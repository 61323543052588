import { Container } from '../../common/container/Container'

export type FileContainerConfig = {
  moduleFullUrl: string
}

export type FileProps = {
  parentContainer: Container
  config: FileContainerConfig
}

export const FILE_API_KEY = Symbol('FILE_API_KEY')
export const FILE_SERVICE_KEY = Symbol('FILE_SERVICE_KEY')
export const FILES_MODULE = Symbol('FILES_MODULE')
