import React, { useEffect, useRef } from 'react'
import { useLoadScript } from '@react-google-maps/api'
import { CustomTextFieldProps } from '../form/CustomTextField'
import { Box, FormControl, Stack } from '@mui/material'
import style from './AddressAutocomplete.module.css'
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url'
import { useMediaQuery } from '@mui/material'

const libraries: Libraries = ['places']

interface AutocompleteProps extends CustomTextFieldProps {
  value?: string | number | readonly string[] | undefined
  onPlaceSelect: (place: google.maps.places.PlaceResult) => void
}

const gray = '#E8E8E8'

const AddressAutocomplete: React.FC<AutocompleteProps> = ({
  value,
  icon,
  containerStyle,
  label,
  errorLabel,
  onPlaceSelect,
  onChange,
}) => {
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const isMobile = useMediaQuery('(max-width:767.98px)')

  const handlePlaceSelect = () => {
    const autocomplete = autocompleteRef.current

    if (autocomplete) {
      const place = autocomplete.getPlace()
      onPlaceSelect(place)
    }
  }

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
    libraries,
  })

  useEffect(() => {
    if (isLoaded && !loadError) {
      const bounds = {
        north: 38.0455,
        south: 37.4759,
        east: -0.5124,
        west: -1.3042,
      }

      const options: google.maps.places.AutocompleteOptions = {
        bounds: new google.maps.LatLngBounds(bounds),
      }
      autocompleteRef.current = new google.maps.places.Autocomplete(inputRef.current!, options)
      autocompleteRef.current.addListener('place_changed', handlePlaceSelect)
    }
  }, [isLoaded, loadError])

  if (loadError) {
    return <div>Error al cargar el mapa</div>
  }

  if (!isLoaded) {
    return <div>Cargando el mapa...</div>
  }

  return (
    <FormControl style={{ ...containerStyle }} fullWidth={true}>
      <label
        style={{
          marginBottom: 5,
          fontWeight: 'bold',
          color: 'black',
          fontSize: isMobile ? '14px' : '16px',
        }}
      >
        {label}
      </label>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Stack style={{ width: '100%' }} direction={'row'}>
          <input
            ref={inputRef}
            type="text"
            value={value}
            style={{
              width: '100%',
              height: 50,
              backgroundColor: gray,
              borderRadius: 20,
              borderWidth: 0,
              padding: 8,
            }}
            className={style.pacContainer}
            onChange={onChange}
            placeholder="Ingresa una dirección"
          />
          {icon && (
            <img
              src={icon.icon}
              onClick={icon.onClick}
              style={{ marginLeft: 20, cursor: 'pointer' }}
            />
          )}
        </Stack>
      </Box>
      {errorLabel && (
        <Box>
          <label style={{ color: 'red' }}>{errorLabel}</label>
        </Box>
      )}
    </FormControl>
  )
}

export default AddressAutocomplete
