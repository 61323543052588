import { v4 as uuidv4 } from 'uuid'

export interface FileDTO {
  id: string
  issueID: string
  base64: string
  type: string
  extension: string
}

export class File {
  private _id: string
  private _issueID: string
  private _base64: string
  private _type: string
  private _extension: string

  constructor(p: FileDTO) {
    this._id = p.id
    this._issueID = p.issueID
    this._base64 = p.base64
    this._type = p.type
    this._extension = p.extension
  }

  get id(): string {
    return this._id
  }

  get extension(): string {
    return this._extension
  }

  get type(): string {
    return this._type
  }

  get issueID(): string {
    return this._issueID
  }

  get base64(): string {
    return this._base64
  }

  set issueID(value: string) {
    this._issueID = value
  }

  set base64(value: string) {
    this._base64 = value
  }
}

export function fromModel(c: File): FileDTO {
  return {
    id: c.id,
    issueID: c.issueID,
    base64: c.base64,
    type: c.type,
    extension: c.extension,
  }
}

export function toModel(d: FileDTO): File {
  return new File(d)
}

export function emptyFileDTO(): FileDTO {
  return {
    id: uuidv4(),
    issueID: '',
    base64: '',
    type: '',
    extension: '',
  }
}

export interface FileQuery {
  id: string
  ids: string[]
  date: Date
  issueID: string
}
