import { ROLES_MODULE, USERS_MODULE } from '../modules/users'
import { ISSUES_MODULE } from '../modules/issues'

const apiURL = process.env.REACT_APP_BACKEND_URL

export const moduleURLs = {
  [USERS_MODULE]: `${apiURL}/users`,
  [ROLES_MODULE]: `${apiURL}/users/roles`,
  [ISSUES_MODULE]: `${apiURL}/issues`,

}
