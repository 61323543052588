import React, { useEffect, useState } from 'react'
import { Box, Stack } from '@mui/material'
import { getIssueContainer } from '../../container/issue-module'
import { STATE_SERVICE_KEY } from '../../modules/issues/container'
import { IStateService } from '../../modules/issues/services/StateService'
import removeIcon from '../../assets/remove.svg'
import { useMediaQuery } from '@mui/material'

export interface MapFilter {
  icon: string
  label: string
  active: boolean
  object?: any
  id: string
}

export type MapProps = {
  onFilterChange?: (filters: MapFilter[]) => void
}

const stateService = getIssueContainer().get<IStateService>(STATE_SERVICE_KEY)

export function MapFilters(props: MapProps) {
  const [filters, setFilters] = useState<MapFilter[]>([])
  const isMobile = useMediaQuery('(max-width:767.98px)')

  useEffect(() => {
    stateService.getAll().subscribe((states) => {
      states &&
        setFilters(
          states.map((s) => {
            return {
              icon: s.icon,
              label: s.name,
              active: true,
              object: s,
              id: s.id,
            }
          })
        )
    })
  }, [])

  function onFilterClick(filter: MapFilter) {
    let newFilters = [...filters]
    let index = newFilters.map((f) => f.label).indexOf(filter.label)
    newFilters[index] = { ...filter, active: !filter.active }
    setFilters(newFilters)
    props.onFilterChange && props.onFilterChange(newFilters)
  }

  function getLabelColor(filter: MapFilter): string {
    return filter.active ? 'var(--color-orange-theme)' : 'black'
  }

  const getMobile = (st: string) => {
    let index = st.indexOf('.')
    let sub = st.substring(0, index)

    return sub + '-mobile.svg'
  }

  const Filter = (filter: MapFilter) => {
    return (
      <Stack
        key={filter.label}
        onClick={() => onFilterClick(filter)}
        style={{ cursor: 'pointer' }}
        direction={'row'}
        spacing={isMobile ? 0 : 1}
      >
        <img src={`../../assets/${isMobile ? getMobile(filter.icon) : filter.icon}`} />
        <label
          key={'l_' + filter.label}
          style={{
            color: getLabelColor(filter),
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            fontSize: isMobile ? 9.8 : 15,
          }}
        >
          {filter.label}
        </label>
      </Stack>
    )
  }

  const anyFilterActive = (): boolean => {
    return filters.some((filter) => filter.active)
  }

  const clearFilters = (): void => {
    const newFilters = [...filters]
    newFilters.forEach((filter) => (filter.active = false))
    setFilters(newFilters)
    props.onFilterChange && props.onFilterChange(newFilters)
  }

  return (
    <Stack direction={'row'} spacing={1}>
      <Box
        paddingX={isMobile ? 1 : 2}
        paddingY={1}
        alignContent={'center'}
        justifyItems={'center'}
        sx={{ borderRadius: isMobile ? 10 : 20, backgroundColor: 'white', boxShadow: 3 }}
      >
        {isMobile ? (
          <>
            <Stack direction={'row'} spacing={isMobile ? -0.5 : 2}>
              {filters.slice(0, 4).map((f) => (
                <Filter key={f.label} active={f.active} icon={f.icon} label={f.label} id={f.id} />
              ))}
            </Stack>
            <Stack direction={'row'} spacing={isMobile ? -0.7 : 2}>
              {filters.slice(4).map((f) => (
                <Filter key={f.label} active={f.active} icon={f.icon} label={f.label} id={f.id} />
              ))}
            </Stack>
          </>
        ) : (
          <Stack direction={'row'} spacing={2}>
            {filters.map((f) => (
              <Filter key={f.label} active={f.active} icon={f.icon} label={f.label} id={f.id} />
            ))}
          </Stack>
        )}
      </Box>
      {anyFilterActive() && (
        <Box
          display={'flex'}
          alignContent={'center'}
          paddingX={1}
          style={{ marginLeft: isMobile ? '1%' : '2%' }}
          sx={{ borderRadius: 10, backgroundColor: 'white', boxShadow: 3 }}
        >
          <img
            onClick={clearFilters}
            src={removeIcon}
            style={{ width: isMobile ? '30px' : '35px', cursor: 'pointer', alignSelf: 'center' }}
          />
        </Box>
      )}
    </Stack>
  )
}
