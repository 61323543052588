import { useEffect, useState } from 'react'
import { Box, Stack, Typography, TextField } from '@mui/material'
import CustomSelect from '../../components/form/CustomSelect'
import { State, emptyStateDTO, toModel } from '../../modules/issues/models/State'
import { getIssueContainer } from '../../container/issue-module'
import { IStateService } from '../../modules/issues/services/StateService'
import { ADDRESS_SERVICE_KEY, STATE_SERVICE_KEY } from '../../modules/issues/container'
import { IssueCategoryName } from '../../modules/issues/models/Issue'
import removeIcon from '../../assets/remove.svg'
import DatePickerTheme from '../../components/datepicker/DatePickerTheme'
import { IAddressService } from 'modules/issues/services/AddressService'
import { ISSUE_FILTERS } from 'modules/users/services/LoggedUserService'
import { useMediaQuery } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'
import FilterListOffIcon from '@mui/icons-material/FilterListOff'

const stateService = getIssueContainer().get<IStateService>(STATE_SERVICE_KEY)
const addresService = getIssueContainer().get<IAddressService>(ADDRESS_SERVICE_KEY)

interface IssuesFilterProps {
  onFilterChange?: (filter: Filter) => void
  issues?: IssueCategoryName[]
  handleClearFilters: () => void
}

export interface Filter {
  since?: Date
  until?: Date
  state?: string
  district?: string
  location?: string
  expedient?: string
}

export function IssuesFilter({ issues, onFilterChange, handleClearFilters }: IssuesFilterProps) {
  const [filter, setFilter] = useState<Filter>({})
  const [states, setStates] = useState<State[]>([])
  const [districts, setDistricts] = useState<string[]>([])
  const isMobile = useMediaQuery('(max-width:767.98px)')
  const [showMobileFilters, setShowMobileFilters] = useState<boolean>(false)

  useEffect(() => {
    let f = localStorage.getItem(ISSUE_FILTERS)
    if (f !== 'undefined') {
      setFilter(JSON.parse(f || 'null'))
    }
  }, [])

  useEffect(() => {
    stateService.getAll().subscribe((st) => {
      if (st) {
        let state = emptyStateDTO()
        state.id = '-1'
        st.push(toModel(state))
        st && setStates(st)
      }
    })
  }, [])

  useEffect(() => {
    addresService.getAll().subscribe((res) => {
      const uniqueArray = res
        ?.map((r) => r.district)
        ?.filter((value, index, self) => {
          return self.indexOf(value) === index
        })

      res && uniqueArray && setDistricts(uniqueArray)
    })
  }, [])

  useEffect(() => {
    filter && onFilterChange && onFilterChange(filter)
  }, [filter, onFilterChange])

  function clearFilters() {
    setFilter({
      since: undefined,
      until: undefined,
      state: undefined,
      district: undefined,
      location: undefined,
      expedient: undefined,
    })
    handleClearFilters()
  }

  function handleFormChange(field: keyof Filter, value: any) {
    setFilter({ ...filter, [field]: value })
  }

  function isFilter(): boolean {
    return (
      filter !== undefined &&
      filter !== null &&
      Object.keys(filter).length > 0 &&
      Object.values(filter).filter((v) => v !== undefined && v !== null && v.toString() !== '')
        .length > 0
    )
  }

  return (
    <Box display={'flex'} style={{ marginTop: 10 }}>
      {isMobile ? (
        <Stack
          display={'inline-flex'}
          alignItems={'center'}
          direction={'column'}
          spacing={2}
          style={{ marginRight: 10, display: 'flex', width: '100%' }}
        >
          <Box
            style={{
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Box style={{ flexDirection: 'row', display: 'flex', justifyContent: 'flex-start' }}>
              <Typography
                style={{ fontWeight: 'bold', fontSize: isMobile ? 14 : 16 }}
                variant="body1"
              >
                Filtrar
              </Typography>
              {showMobileFilters ? (
                <FilterListOffIcon
                  style={{
                    alignSelf: 'center',
                    marginLeft: '5%',
                    width: '30px',
                    cursor: 'pointer',
                  }}
                  onClick={() => setShowMobileFilters(false)}
                />
              ) : (
                <FilterListIcon
                  style={{
                    alignSelf: 'center',
                    marginLeft: '5%',
                    width: '30px',
                    cursor: 'pointer',
                  }}
                  onClick={() => setShowMobileFilters(true)}
                />
              )}
            </Box>
            {isFilter() && (
              <img
                onClick={() => clearFilters()}
                src={removeIcon}
                style={{ width: '25px', cursor: 'pointer', alignSelf: 'center' }}
              />
            )}
          </Box>

          {showMobileFilters && (
            <Box>
              <Box
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  width: '100%',
                  alignSelf: 'center',
                  justifyContent: 'center',
                }}
              >
                <DatePickerTheme
                  value={filter?.since}
                  placeholder={'Desde'}
                  onChange={(date) => handleFormChange('since', date)}
                />

                <Typography
                  style={{ fontWeight: 'bold', marginLeft: '1%', marginRight: '1%' }}
                  variant="body1"
                >
                  -
                </Typography>

                <DatePickerTheme
                  value={filter?.until}
                  placeholder={'Hasta'}
                  onChange={(date) => handleFormChange('until', date)}
                />
              </Box>
              <Box
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  width: '100%',
                  alignSelf: 'center',
                  justifyContent: 'center',
                  marginTop: '4%',
                }}
              >
                <CustomSelect
                  showLabel={false}
                  id={'state'}
                  placeholder={'Estado'}
                  fullWidth={true}
                  style={{ height: 35, fontSize: isMobile ? 11 : 16, alignSelf: 'center' }}
                  value={filter?.state || ''}
                  onChangeOption={(value) => handleFormChange('state', value)}
                  options={states?.map((it) => {
                    return { value: it.id, label: it.name }
                  })}
                />

                <CustomSelect
                  placeholder={'Distrito'}
                  showLabel={false}
                  fullWidth={true}
                  id={'district'}
                  style={{
                    height: 35,
                    fontSize: isMobile ? 11 : 16,
                    alignSelf: 'center',
                    marginLeft: '2%',
                  }}
                  value={filter?.district || ''}
                  onChangeOption={(value) => handleFormChange('district', value)}
                  options={districts
                    ?.filter((d) => d !== '-')
                    ?.map((d) => {
                      return { value: d, label: d === '-' ? 'Sin Distrito' : d }
                    })}
                />
              </Box>
              <Box
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  width: '100%',
                  alignSelf: 'center',
                  justifyContent: 'center',
                  marginTop: '4%',
                }}
              >
                <TextField
                  fullWidth
                  variant={'outlined'}
                  id={'expedient'}
                  type={'string'}
                  onChange={(e) => handleFormChange('expedient', e.target.value)}
                  value={filter?.expedient || ''}
                  InputProps={{
                    placeholder: 'Expediente',
                    disableUnderline: true,
                    style: {
                      border: 'none',
                      backgroundColor: '#E8E8E8',
                      borderRadius: 20,
                      height: 35,
                      fontSize: isMobile ? 11 : 16,
                      alignSelf: 'center',
                    },
                  }}
                  sx={{
                    '& fieldset': { border: 'none' },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <TextField
                  fullWidth
                  variant={'outlined'}
                  id={'location'}
                  type={'string'}
                  onChange={(e) => handleFormChange('location', e.target.value)}
                  value={filter?.location || ''}
                  InputProps={{
                    placeholder: 'Ubicación',
                    disableUnderline: true,
                    style: {
                      border: 'none',
                      backgroundColor: '#E8E8E8',
                      borderRadius: 20,
                      height: 35,
                      fontSize: isMobile ? 11 : 16,
                      alignSelf: 'center',
                      marginLeft: '2%',
                    },
                  }}
                  sx={{
                    '& fieldset': { border: 'none' },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Box>
          )}
        </Stack>
      ) : (
        <Stack
          display={'inline-flex'}
          alignItems={'center'}
          direction={'row'}
          spacing={2}
          style={{ marginRight: 10 }}
        >
          <Typography style={{ fontWeight: 'bold', fontSize: isMobile ? 14 : 16 }} variant="body1">
            Filtrar
          </Typography>

          <DatePickerTheme
            value={filter?.since}
            placeholder={'Desde'}
            onChange={(date) => handleFormChange('since', date)}
          />

          <Typography style={{ fontWeight: 'bold' }} variant="body1">
            -
          </Typography>

          <DatePickerTheme
            value={filter?.until}
            placeholder={'Hasta'}
            onChange={(date) => handleFormChange('until', date)}
          />

          <CustomSelect
            showLabel={false}
            id={'state'}
            placeholder={'Estado'}
            fullWidth={true}
            style={{ height: 35, fontSize: isMobile ? 11 : 16, alignSelf: 'center' }}
            value={filter?.state || ''}
            onChangeOption={(value) => handleFormChange('state', value)}
            options={states?.map((it) => {
              return { value: it.id, label: it.name }
            })}
          />

          <CustomSelect
            placeholder={'Distrito'}
            showLabel={false}
            fullWidth={true}
            id={'district'}
            style={{ height: 35, fontSize: isMobile ? 11 : 16, alignSelf: 'center' }}
            value={filter?.district || ''}
            onChangeOption={(value) => handleFormChange('district', value)}
            options={districts
              ?.filter((d) => d !== '-')
              ?.map((d) => {
                return { value: d, label: d === '-' ? 'Sin Distrito' : d }
              })}
          />

          <TextField
            fullWidth
            variant={'outlined'}
            id={'expedient'}
            type={'string'}
            onChange={(e) => handleFormChange('expedient', e.target.value)}
            value={filter?.expedient || ''}
            InputProps={{
              placeholder: 'Expediente',
              disableUnderline: true,
              style: {
                border: 'none',
                backgroundColor: '#E8E8E8',
                borderRadius: 20,
                height: 35,
                fontSize: isMobile ? 11 : 16,
                alignSelf: 'center',
              },
            }}
            sx={{
              '& fieldset': { border: 'none' },
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            fullWidth
            variant={'outlined'}
            id={'location'}
            type={'string'}
            onChange={(e) => handleFormChange('location', e.target.value)}
            value={filter?.location || ''}
            InputProps={{
              placeholder: 'Ubicación',
              disableUnderline: true,
              style: {
                border: 'none',
                backgroundColor: '#E8E8E8',
                borderRadius: 20,
                height: 35,
                fontSize: isMobile ? 11 : 16,
                alignSelf: 'center',
              },
            }}
            sx={{
              '& fieldset': { border: 'none' },
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />

          {isFilter() && (
            <img
              onClick={() => clearFilters()}
              src={removeIcon}
              style={{ width: '35px', cursor: 'pointer', alignSelf: 'center' }}
            />
          )}
        </Stack>
      )}
    </Box>
  )
}
