import { v4 as uuidv4 } from 'uuid'
import { emptyUserDTO, User } from '../../users/models/User'

export interface WorknoteDTO {
  id: string
  date: Date
  userID: string
  issue_id: string
  description: string
  remarks: string
  lat: number
  lng: number
  address: string
  finished: boolean
}

export function emptyWorknoteDTO() {
  return {
    id: uuidv4(),
    userID: '',
    issue_id: '',
    date: new Date(),
    description: '',
    remarks: '',
    lat: 0.0,
    lng: 0.0,
    address: '',
    finished: false,
  }
}

export class Worknote {
  private readonly _id: string
  private readonly _date: Date
  private readonly _userID: string
  private readonly _issue_id: string
  private _description: string
  private readonly _remarks: string
  private readonly _lat: number
  private readonly _lng: number
  private readonly _address: string
  private _finished: boolean

  constructor(p: WorknoteDTO) {
    this._id = p.id || uuidv4()
    this._date = p.date
    this._userID = p.userID
    this._issue_id = p.issue_id
    this._description = p.description
    this._lat = p.lat
    this._lng = p.lng
    this._address = p.address
    this._remarks = p.remarks
    this._finished = p.finished
  }

  get id(): string {
    return this._id
  }

  get date(): Date {
    return this._date
  }

  get remarks(): string {
    return this._remarks
  }

  get description(): string {
    return this._description
  }

  get lat(): number {
    return this._lat
  }

  get lng(): number {
    return this._lng
  }

  get userID(): string {
    return this._userID
  }

  get issue_id(): string {
    return this._issue_id
  }

  get finished(): boolean {
    return this._finished
  }

  get address(): string {
    return this._address
  }

  set finished(value: boolean) {
    this._finished = value
  }

  set description(value: string) {
    this._description = value
  }

  toDTO(): WorknoteDTO {
    return {
      id: this.id,
      date: this.date,
      userID: this.userID,
      issue_id: this.issue_id,
      description: this.description,
      lat: this.lat,
      lng: this.lng,
      address: this.address,
      remarks: this.remarks,
      finished: this.finished,
    }
  }
}

export function toModel(d: WorknoteDTO): Worknote {
  return new Worknote(d)
}

export function fromModel(d: Worknote): WorknoteDTO {
  return {
    id: d.id,
    date: d.date,
    userID: d.userID,
    issue_id: d.issue_id,
    description: d.description,
    lat: d.lat,
    lng: d.lng,
    address: d.address,
    remarks: d.remarks,
    finished: d.finished,
  }
}

export interface WorknoteQuery {}
