import { Container, IInit } from '../../../common/container/Container'
import { IssueContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { catchError, map } from 'rxjs/operators'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { ItemList } from '../../../common/models/ItemList'
import { Address, AddressDTO, emptyAddressDTO, toModel } from '../models/Address'

export interface IAddressApi extends IInit {
  getAll(): Observable<ItemList<AddressDTO> | undefined>

  getByID(id: string): Observable<Address>

  add(e: AddressDTO): Observable<Address | undefined>

  update(e: AddressDTO): Observable<Address | undefined>
}

export class AddressApi implements IAddressApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as IssueContainerConfig).moduleFullUrl
  }

  add(e: AddressDTO): Observable<Address | undefined> {
    return this._httpClient
      .post<AddressDTO>({
        url: `${this._url}/addresses`,
        body: e,
      })
      .pipe(
        map<AddressDTO, Address>((dto) => toModel(dto)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getByID(id: string): Observable<Address> {
    return this._httpClient.get<AddressDTO>({ url: `${this._url}/addresses/${id}` }).pipe(
      map<AddressDTO, Address>((dto) => toModel(dto)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(toModel(emptyAddressDTO()))
      })
    )
  }

  update(e: AddressDTO): Observable<Address | undefined> {
    return this._httpClient.put<AddressDTO>({ url: `${this._url}/addresses`, body: e }).pipe(
      map<AddressDTO, Address>((dto) => toModel(dto)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getAll(): Observable<ItemList<AddressDTO> | undefined> {
    return this._httpClient
      .get<ItemList<AddressDTO>>({
        url: `${this._url}/addresses`,
      })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }
}
