import React, { useState, useEffect } from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { Box, Grid } from '@mui/material'
import DividerTheme from '../../components/divider/DividerTheme'
import CustomTextField from '../../components/form/CustomTextField'
import CustomButton from '../../components/form/CustomButton'
import { emptyWorknoteDTO, WorknoteDTO } from '../../modules/worknotes/models/Worknote'
import { getUserContainer } from '../../container/user-module'
import { useNavigate, useParams } from 'react-router-dom'
import { getIssueContainer } from '../../container/issue-module'
import { IUserService, USER_SERVICE_KEY } from '../../modules/users'
import { WorkDTO, emptyWorkDTO } from 'modules/issues/models/Work'
import { IWorkService } from 'modules/issues/services/WorkService'
import { WORK_SERVICE_KEY } from 'modules/issues/container'
import CustomSelect from 'components/form/CustomSelect'
import { FileService } from 'modules/files/services/FileService'
import { FILE_SERVICE_KEY } from 'modules/files'
import CustomInputFileWorknote from 'components/form/CustomInputFileWorknote'
import { useMediaQuery } from '@mui/material'

const userContainer = getUserContainer().get<IUserService>(USER_SERVICE_KEY)
const workService = getIssueContainer().get<IWorkService>(WORK_SERVICE_KEY)
const fileService = getIssueContainer().get<FileService>(FILE_SERVICE_KEY)

export type WorknoteEditProps = {
  id?: string
} & RouteProps

export function WorknoteEdit(props: WorknoteEditProps) {
  const title = props.title || ''
  const { id } = useParams()
  const navigate = useNavigate()
  const [worknote, setWorknote] = React.useState<WorknoteDTO>(emptyWorknoteDTO())
  const [work, setWork] = useState<WorkDTO>(emptyWorkDTO())
  const [image1, setImage1] = useState<any>()
  const [image2, setImage2] = useState<any>()
  const states = ['Obra en ejecución', 'Obra en ejecutada']
  const isMobile = useMediaQuery('(max-width:767.98px)')

  useEffect(() => {
    setWork({
      ...work,
      issueID: id || '',
      state: states[0],
    })
  }, [])

  React.useEffect(() => {
    userContainer.getAll().subscribe((users) => {
      setWorknote({
        ...worknote,
        issue_id: id || '',
        userID: users && users?.length > 0 ? users[0].id : '',
      })
    })
  }, [])

  function goBack() {
    navigate(-1)
  }

  function onChangeField(field: keyof WorkDTO, value: any) {
    if (work) {
      setWork({ ...work, [field]: value })
    }
  }

  const save = async () => {
    if (image1) {
      await fileService.add(image1).toPromise()
    }

    if (image2) {
      await fileService.add(image2).toPromise()
    }

    let work2 = Object.assign(
      { ...work },
      { image1ID: image1 ? image1.id : '', image2ID: image2 ? image2.id : '', issueID: id }
    )

    workService.add(work2).subscribe((res) => {
      goBack()
    })
  }

  const handleChangeImage = (type: string, fileb64: string) => {
    let start = fileb64.indexOf('/') + 1
    let end = fileb64.indexOf(';')
    let extension = fileb64.substring(start, end)

    let img = {
      id: id + '-obra',
      issueID: id,
      base64: fileb64.substring(fileb64.indexOf(',') + 1, fileb64.length),
      type: 'work',
      extension: extension,
    }

    type == 'image1' ? setImage1(img) : setImage2(img)
  }

  return (
    <div>
      <Box style={{ padding: isMobile ? 25 : 24 }}>
        <DividerTheme title={title.toUpperCase()} />
        <Box style={{ marginTop: 20 }}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <CustomTextField
                onChange={(event) => onChangeField('description', event.target.value)}
                fullWidth={true}
                rows={8}
                multiline
                value={work?.description}
                label={'Descripción (máx. 140 caracteres)'}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4} style={{ marginTop: '2%' }}>
            <Grid item xs={4}>
              <CustomInputFileWorknote
                onFileRead={(file) => file && handleChangeImage('image1', file.base64)}
                base64File={image1 ? { name: 'IMAGE 1', base64: image1.base64 } : undefined}
                fullWidth={true}
                label={'Imagen'}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomInputFileWorknote
                onFileRead={(file) => file && handleChangeImage('image2', file.base64)}
                base64File={image2 ? { name: 'IMAGE 2', base64: image2.base64 } : undefined}
                fullWidth={true}
                label={'Imagen 2'}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomSelect
                label={'Estado'}
                id={'type'}
                value={work.state}
                onChangeOption={(value) => onChangeField('state', value)}
                defaultOneOption
                options={states?.map((it) => {
                  return { value: it, label: it }
                })}
              />
            </Grid>
          </Grid>
          <Box style={{ marginTop: 60 }} display={'flex'} justifyContent={'space-between'}>
            <CustomButton onClick={() => save()} color={'primary'}>
              {'GUARDAR'}
            </CustomButton>
            <CustomButton onClick={() => goBack()}>{'CANCELAR'}</CustomButton>
          </Box>
        </Box>
      </Box>
    </div>
  )
}
