import { v4 as uuidv4 } from 'uuid'

export interface IssueTypeDTO {
  id: string
  name: string
}

export function emptyIssueTypeDTO() {
  return {
    id: uuidv4(),
    name: '',
  }
}

export class IssueType {
  private readonly _id: string
  private readonly _name: string
  constructor(p: IssueTypeDTO) {
    this._id = p.id || uuidv4()
    this._name = p.name
  }

  get id(): string {
    return this._id
  }

  get name(): string {
    return this._name
  }

  toDTO(): IssueTypeDTO {
    return {
      id: this.id,
      name: this.name,
    }
  }
}

export function toModel(d: IssueTypeDTO): IssueType {
  return new IssueType(d)
}

export function fromModel(d: IssueType): IssueTypeDTO {
  return {
    id: d.id,
    name: d.name,
  }
}
