import { Container, IInit } from '../../../common/container/Container'
import { IssueContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { catchError, map } from 'rxjs/operators'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { emptyWorkDTO, Work, WorkDTO, toModel } from '../models/Work'
import { ItemList, emptyList } from '../../../common/models/ItemList'
import { IWorkService } from '../services/WorkService'
import { Query } from 'common/api/Query'
import { prepareURL } from 'common/api/http-helpers'

export interface IWorkApi extends IInit {
  getByID(id: string): Observable<Work>

  add(e: WorkDTO): Observable<Work | undefined>

  update(e: WorkDTO): Observable<Work | undefined>

  getFilteredList(q: Query<Work>): Observable<ItemList<Work>>

  getCountByID(id: string): Observable<number>
}

export class WorkApi implements IWorkApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IWorkService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IWorkService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as IssueContainerConfig).moduleFullUrl
  }

  add(e: WorkDTO): Observable<Work | undefined> {
    return this._httpClient
      .post<WorkDTO>({
        url: `${this._url}/work`,
        body: e,
      })
      .pipe(
        map<WorkDTO, Work>((dto) => toModel(dto)),
        catchError((err) => {
          return of(undefined)
        })
      )
  }

  getByID(id: string): Observable<Work> {
    return this._httpClient.get<WorkDTO>({ url: `${this._url}/work/${id}` }).pipe(
      map<WorkDTO, Work>((dto) => toModel(dto)),
      catchError((err) => {
        return of(toModel(emptyWorkDTO()))
      })
    )
  }

  update(e: WorkDTO): Observable<Work | undefined> {
    return this._httpClient.put<WorkDTO>({ url: `${this._url}/work`, body: e }).pipe(
      map<WorkDTO, Work>((dto) => toModel(dto)),
      catchError((err) => {
        return of(undefined)
      })
    )
  }

  getFilteredList(q: Query<Work>): Observable<ItemList<Work>> {
    return this._httpClient.get<ItemList<Work>>({ url: prepareURL(this._url + '/work', q) }).pipe(
      map<ItemList<WorkDTO>, ItemList<Work>>((dto) => {
        const itemList = emptyList<Work>()
        itemList.count = dto.count
        itemList.items = dto.items.map((d) => toModel(d))
        return itemList
      }),
      catchError((err) => {
        return of(emptyList<Work>())
      })
    )
  }

  getCountByID(id: string): Observable<number> {
    return this._httpClient
      .get<number>({
        url: `${this._url}/works-by-issue-count/${id}`,
      })
      .pipe(
        map((res) => res),
        catchError((err) => {
          return of(-1)
        })
      )
  }
}
