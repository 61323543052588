import React from 'react'
import { State } from '../modules/issues/models/State'
import { useMediaQuery } from '@mui/material'

interface StateButtonProps {
  state?: State
}

function StateButton({ state }: StateButtonProps) {
  const isMobile = useMediaQuery('(max-width:767.98px)')

  return (
    <div
      style={{
        borderRadius: 12,
        textAlign: 'center',
        paddingTop: 4,
        paddingBottom: 4,
        padding: 5.5,
        maxWidth: 250,
        border: 'solid 2px ' + state?.color,
        color: state?.color,
        width: '100%',
        fontWeight: 'bold',
        fontSize: isMobile ? 12.5 : 15,
      }}
    >
      <label>{state?.name.toUpperCase()}</label>
    </div>
  )
}

export default StateButton
