import { Container, IInit } from '../../../common/container/Container'
import { IssueContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { catchError, map } from 'rxjs/operators'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { IssueHistory, IssueHistoryDTO, IssueHistoryQuery } from '../models/IssueHistory'
import { ItemList } from '../../../common/models/ItemList'
import { Query } from '../../../common/api/Query'
import { prepareURL } from '../../../common/api/http-helpers'

export interface IIssueHistoryApi extends IInit {
  getByIssueID(id: string): Observable<IssueHistory[] | undefined>

  getByID(id: string): Observable<IssueHistory | undefined>

  getPaginatedListByIssueID(
    id: string,
    q: Query<IssueHistoryQuery>
  ): Observable<IssueHistory[] | undefined>

  add(e: IssueHistoryDTO): Observable<IssueHistory | undefined>

  update(e: IssueHistoryDTO): Observable<IssueHistory | undefined>

  getCountByIssueID(id: string): Observable<number>
}

export class IssueHistoryApi implements IIssueHistoryApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as IssueContainerConfig).moduleFullUrl
  }

  add(e: IssueHistoryDTO): Observable<IssueHistory | undefined> {
    return this._httpClient
      .post<IssueHistory>({
        url: `${this._url}/issue-histories`,
        body: e,
      })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getByID(id: string): Observable<IssueHistory | undefined> {
    return this._httpClient
      .get<IssueHistory | undefined>({ url: `${this._url}/issue-histories/${id}` })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  update(e: IssueHistoryDTO): Observable<IssueHistory | undefined> {
    return this._httpClient
      .put<IssueHistory>({ url: `${this._url}/issue-histories/${e.id}`, body: e })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getByIssueID(id: string): Observable<IssueHistory[] | undefined> {
    return this._httpClient
      .get<ItemList<IssueHistory>>({
        url: `${this._url}/issue-histories-by-issue/${id}`,
      })
      .pipe(
        map((res) => res?.items),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of([])
        })
      )
  }

  getPaginatedListByIssueID(
    id: string,
    q: Query<IssueHistoryQuery>
  ): Observable<IssueHistory[] | undefined> {
    return this._httpClient
      .get<ItemList<IssueHistory>>({
        url: prepareURL(`${this._url}/paginated-issue-histories-by-issue/${id}`, q),
      })
      .pipe(
        map((res) => res?.items),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of([])
        })
      )
  }

  getCountByIssueID(id: string): Observable<number> {
    return this._httpClient
      .get<number>({
        url: `${this._url}/issue-histories-by-issue-count/${id}`,
      })
      .pipe(
        map((res) => res),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(-1)
        })
      )
  }
}
