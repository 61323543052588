import { Container, IInit } from '../../../common/container/Container'
import { Observable } from 'rxjs'
import { Work, WorkDTO, toModel } from '../models/Work'
import { IWorkApi } from '../api/WorkApi'
import { WORK_SERVICE_KEY } from '../container'
import { Query } from 'common/api/Query'
import { ItemList } from 'common/models/ItemList'

type Props = {
  apiKey: symbol
}

export interface IWorkService extends IInit {
  getByID(id: string): Observable<Work>

  add(e: WorkDTO): Observable<Work | undefined>

  update(e: WorkDTO): Observable<Work | undefined>

  getFilteredList(q: Query<Work>): Observable<ItemList<Work>>

  getCountByID(id: string): Observable<number>
}

export class WorkService implements IWorkService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IWorkApi
  private _statusService!: IWorkService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IWorkApi>(this._apiKey)
    this._statusService = this._container.get<IWorkService>(WORK_SERVICE_KEY)
  }

  add(e: WorkDTO): Observable<Work | undefined> {
    return this._api.add(e)
  }

  getByID(id: string): Observable<Work> {
    return this._api.getByID(id)
  }

  update(e: WorkDTO): Observable<Work | undefined> {
    return this._api.update(e)
  }

  getFilteredList(q: Query<Work>): Observable<ItemList<Work>> {
    return this._api.getFilteredList(q).pipe()
  }

  getCountByID(id: string): Observable<number> {
    return this._api.getCountByID(id)
  }
}
