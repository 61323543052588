import { Container, IInit } from '../../../common/container/Container'
import { IssueContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { catchError, map } from 'rxjs/operators'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { emptyStateDTO, State, StateDTO, toModel } from '../models/State'
import { ItemList } from '../../../common/models/ItemList'

export interface IStateApi extends IInit {
  getAll(): Observable<ItemList<StateDTO> | undefined>

  getByID(id: string): Observable<State>

  add(e: StateDTO): Observable<State | undefined>

  update(e: StateDTO): Observable<State | undefined>
}

export class StateApi implements IStateApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as IssueContainerConfig).moduleFullUrl
  }

  add(e: StateDTO): Observable<State | undefined> {
    return this._httpClient
      .post<StateDTO>({
        url: `${this._url}/states`,
        body: e,
      })
      .pipe(
        map<StateDTO, State>((dto) => toModel(dto)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getByID(id: string): Observable<State> {
    return this._httpClient.get<StateDTO>({ url: `${this._url}/states/${id}` }).pipe(
      map<StateDTO, State>((dto) => toModel(dto)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(toModel(emptyStateDTO()))
      })
    )
  }

  update(e: StateDTO): Observable<State | undefined> {
    return this._httpClient.put<StateDTO>({ url: `${this._url}/states/${e.id}`, body: e }).pipe(
      map<StateDTO, State>((dto) => toModel(dto)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getAll(): Observable<ItemList<StateDTO> | undefined> {
    return this._httpClient
      .get<ItemList<StateDTO>>({
        url: `${this._url}/states`,
      })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }
}
