import { Container, IInit } from '../../../common/container/Container'
import { Observable } from 'rxjs'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { map } from 'rxjs/operators'
import { IssueType, IssueTypeDTO, toModel } from '../models/IssueType'
import { IIssueTypeApi } from '../api/IssueTypeApi'

type Props = {
  apiKey: symbol
}

export interface IIssueTypeService extends IInit {
  getByID(id: string): Observable<IssueType>

  getAll(): Observable<IssueType[] | undefined>

  add(e: IssueTypeDTO): Observable<IssueType | undefined>

  update(e: IssueTypeDTO): Observable<IssueType | undefined>
}

export class IssueTypeService implements IIssueTypeService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IIssueTypeApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IIssueTypeApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: IssueTypeDTO): Observable<IssueType | undefined> {
    return this._api.add(e)
  }

  getByID(id: string): Observable<IssueType> {
    return this._api.getByID(id)
  }

  update(e: IssueTypeDTO): Observable<IssueType | undefined> {
    return this._api.update(e)
  }

  getAll(): Observable<IssueType[] | undefined> {
    let all = this._api.getAll()
    return all.pipe(map(res => res?.items.map(i => toModel(i))))
  }
}
