import { v4 as uuidv4 } from 'uuid'

export interface IssueHistoryDTO {
  id: string
  userID: string
  createdAt: Date
  issueID: string
  remarks: string
}

export function emptyIssueHistoryDTO() {
  return {
    id: uuidv4(),
    userID: '',
    createdAt: new Date(),
    issueID: '',
    remarks: '',
  }
}

export class IssueHistory {
  private readonly _id: string
  private readonly _userID: string
  private readonly _createdAt: Date
  private readonly _issueID: string
  private readonly _remarks: string

  constructor(p: IssueHistoryDTO) {
    this._id = p.id || uuidv4()
    this._userID = p.userID
    this._createdAt = p.createdAt
    this._issueID = p.issueID
    this._remarks = p.remarks
  }

  get id(): string {
    return this._id
  }

  get userID(): string {
    return this._userID
  }

  get createdAt(): Date {
    return this._createdAt
  }

  get issueID(): string {
    return this._issueID
  }

  get remarks(): string {
    return this._remarks
  }

  toDTO(): IssueHistoryDTO {
    return {
      id: this.id,
      userID: this.userID,
      createdAt: this.createdAt,
      issueID: this.issueID,
      remarks: this.remarks,
    }
  }
}

export function toModel(p: IssueHistoryDTO): IssueHistory {
  return new IssueHistory(p)
}

export function fromModel(p: IssueHistory): IssueHistoryDTO {
  return p.toDTO()
}

export interface IssueHistoryQuery {}
