import { Container, IInit } from '../../../common/container/Container'
import { IssueContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { catchError, map } from 'rxjs/operators'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Category, Issue, IssueCategoryName, IssueDTO, IssueQuery } from '../models/Issue'
import { ItemList } from '../../../common/models/ItemList'
import { Query } from 'common/api/Query'
import { prepareURL } from 'common/api/http-helpers'
import { emptyFileDTO, FileDTO, File as F } from '../../files/models/File'

export interface IIssueApi extends IInit {
  getAll(): Observable<IssueDTO[]>

  getByWorkerID(id: string): Observable<IssueDTO[]>

  getByID(id: string): Observable<IssueDTO | undefined>

  add(e: IssueDTO): Observable<IssueDTO | undefined>

  update(e: IssueDTO): Observable<IssueDTO | undefined>

  delete(id: string): Observable<boolean>

  getFilteredList(q: Query<IssueQuery>): Observable<IssueCategoryName[]>

  getDashBoardData(): Observable<any[] | undefined>

  getIssueCount(q: Query<IssueQuery>): Observable<number>

  generateReport(id: string): Observable<FileDTO>

  generateReports(ids: string[]): Observable<FileDTO>

  getCategories(): Observable<Category[]>

  downloadExcel(q: Query<IssueQuery>): Observable<F | undefined>
}

export class IssueApi implements IIssueApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as IssueContainerConfig).moduleFullUrl
  }

  add(e: IssueDTO): Observable<IssueDTO | undefined> {
    return this._httpClient
      .post<IssueDTO>({
        url: `${this._url}/issues`,
        body: e,
      })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getByID(id: string): Observable<IssueDTO | undefined> {
    return this._httpClient.get<IssueDTO>({ url: `${this._url}/issues/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getByWorkerID(id: string): Observable<IssueDTO[]> {
    return this._httpClient
      .post<ItemList<IssueDTO>>({ url: `${this._url}/issues-by-user/${id}` })
      .pipe(
        map((res) => res?.items),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of([])
        })
      )
  }

  update(e: IssueDTO): Observable<IssueDTO | undefined> {
    return this._httpClient.put<IssueDTO>({ url: `${this._url}/issues`, body: e }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient
      .delete({
        url: prepareURL(`${this._url}/issues/${id}`),
      })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(false)
        })
      )
  }

  getAll(): Observable<IssueDTO[]> {
    return this._httpClient
      .get<ItemList<IssueDTO>>({
        url: `${this._url}/issues`,
      })
      .pipe(
        map((res) => {
          return res?.items
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of([])
        })
      )
  }

  getFilteredList(q: Query<IssueQuery>): Observable<IssueCategoryName[]> {
    return this._httpClient
      .get<IssueCategoryName[]>({
        url: prepareURL(`${this._url}/paginatedList`, q),
      })
      .pipe(
        map((res) => {
          console.log(res)
          return res
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of([])
        })
      )
  }

  getDashBoardData(): Observable<any[]> {
    return this._httpClient
      .get<any[]>({
        url: `${this._url}/dashboardData`,
      })
      .pipe(
        map((res) => {
          return res
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of([])
        })
      )
  }

  getIssueCount(q: Query<IssueQuery>): Observable<number> {
    return this._httpClient
      .get<number>({
        url: prepareURL(`${this._url}/issuesCount`, q),
      })
      .pipe(
        map((res) => res),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(-1)
        })
      )
  }

  generateReport(id: string): Observable<FileDTO> {
    return this._httpClient
      .get<FileDTO>({
        url: prepareURL(`${this._url}/generate-report/${id}`),
      })
      .pipe(
        map((res) => res),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyFileDTO())
        })
      )
  }

  generateReports(ids: string[]): Observable<FileDTO> {
    return this._httpClient
      .post<FileDTO>({
        url: `${this._url}/generateReports`,
        body: { IDs: ids },
      })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyFileDTO())
        })
      )
  }

  getCategories(): Observable<Category[]> {
    return this._httpClient
      .get<ItemList<Category>>({
        url: `${this._url}/categories`,
      })
      .pipe(
        map((res) => res?.items),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of([])
        })
      )
  }

  downloadExcel(q: Query<IssueQuery>): Observable<F | undefined> {
    return this._httpClient.get<F>({ url: prepareURL(this._url + '/downloadExcel', q) }).pipe(
      map((d) => {
        return d
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }
}
