import { Container, IInit } from '../../../common/container/Container'
import { IssueContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { catchError, map } from 'rxjs/operators'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { ItemList } from '../../../common/models/ItemList'
import { emptyIssueTypeDTO, toModel, IssueType, IssueTypeDTO } from '../models/IssueType'

export interface IIssueTypeApi extends IInit {
  getAll(): Observable<ItemList<IssueTypeDTO> | undefined>

  getByID(id: string): Observable<IssueType>

  add(e: IssueTypeDTO): Observable<IssueType | undefined>

  update(e: IssueTypeDTO): Observable<IssueType | undefined>
}

export class IssueTypeApi implements IIssueTypeApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as IssueContainerConfig).moduleFullUrl
  }

  add(e: IssueTypeDTO): Observable<IssueType | undefined> {
    return this._httpClient
      .post<IssueTypeDTO>({
        url: `${this._url}/issue-types`,
        body: e,
      })
      .pipe(
        map<IssueTypeDTO, IssueType>((dto) => toModel(dto)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getByID(id: string): Observable<IssueType> {
    return this._httpClient.get<IssueTypeDTO>({ url: `${this._url}/issue-types/${id}` }).pipe(
      map<IssueTypeDTO, IssueType>((dto) => toModel(dto)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(toModel(emptyIssueTypeDTO()))
      })
    )
  }

  update(e: IssueTypeDTO): Observable<IssueType | undefined> {
    return this._httpClient.put<IssueTypeDTO>({ url: `${this._url}/issue-types/${e.id}`, body: e }).pipe(
      map<IssueTypeDTO, IssueType>((dto) => toModel(dto)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getAll(): Observable<ItemList<IssueTypeDTO> | undefined> {
    return this._httpClient
      .get<ItemList<IssueTypeDTO>>({
        url: `${this._url}/issue-types`,
      })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }
}
