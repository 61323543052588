import i18n, { Language } from '../../../i18n'

export type Related = {
  id: string
  kind: Kind
}

export type LoggedUserProps = {
  id: string
  name: string
  email: string
  gender: number
  birthDate: Date
  dni: string
  settings: string
  sessionExpires: Date
  roles: string[]
  permissions: string[]
  language: Language
}

export class LoggedUser {
  private readonly _id: string
  private readonly _name: string
  private readonly _email: string
  private readonly _gender: number
  private readonly _birthDate: Date
  private readonly _dni: string
  private _settings: string
  private readonly _sessionExpires: Date
  private _roles: string[]
  private _permissions: string[]
  private _language: Language

  constructor(p: LoggedUserProps) {
    this._id = p.id
    this._name = p.name || ''
    this._email = p.email || ''
    this._gender = p.gender
    this._birthDate = p.birthDate || new Date()
    this._dni = p.dni || ''
    this._settings = p.settings
    this._sessionExpires = new Date(p.sessionExpires)
    this._roles = p.roles
    this._permissions = p.permissions
    this._language = p.language || Language.SPANISH
  }

  get id(): string {
    return this._id
  }

  get name(): string {
    return this._name
  }

  get email(): string {
    return this._email
  }

  get gender(): number {
    return this._gender
  }

  get birthDate(): Date {
    return this._birthDate
  }

  get dni(): string {
    return this._dni
  }

  get settings(): string {
    return this._settings
  }

  set settings(value: string) {
    this._settings = value
  }

  get sessionExpires(): Date {
    return this._sessionExpires
  }

  get permissions(): string[] {
    return this._permissions
  }

  set permissions(value: string[]) {
    this._permissions = value
  }

  get roles(): string[] {
    return this._roles
  }

  set roles(value: string[]) {
    this._roles = value
  }

  get language(): Language {
    return this._language
  }

  set language(value: Language) {
    this._language = value
  }
}

export enum Kind {
  NotSet = 100,
}

export const kinds = (): Record<Kind, string> => ({
  [Kind.NotSet]: i18n.t('NotSet'),
})
