import React, { useEffect, useState } from 'react'
import { RouteProps } from '../../routes/AppRouter'
import GoogleMapReact, { Coords } from 'google-map-react'
import { MapFilter, MapFilters } from './MapFilters'
import { IIssueService } from '../../modules/issues'
import { ISSUE_SERVICE_KEY } from '../../modules/issues/container'
import { Issue, IssueDTO } from '../../modules/issues/models/Issue'
import { AddressPickerDialog, AddressPickerDialogProps } from './AddressPickerDialog'
import { ViewIssueDialog } from './ViewIssueDialog'
import { getIssueContainer } from '../../container/issue-module'
import { ROUTE_ISSUES_NEW } from '../../routes/routes-constants'
import { AddressDTO } from '../../modules/issues/models/Address'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'

export type MapProps = {} & RouteProps

const Marker = ({
  id,
  icon,
  lat,
  lng,
  onClick,
}: {
  id: string
  icon: string
  lat: number
  lng: number
  onClick: (id: string) => void
}) => (
  <img
    style={{ cursor: 'pointer' }}
    onClick={(event) => {
      event.stopPropagation()
      onClick(id)
    }}
    src={`../../assets/${icon}`}
  />
)

const issueService = getIssueContainer().get<IIssueService>(ISSUE_SERVICE_KEY)

export function Map(props: MapProps) {
  const [issues, setIssues] = useState<Issue[]>([])
  const [filteredIssues, setFilteredIssues] = useState<Issue[]>([])
  const [newIssue, setNewIssue] = useState<AddressPickerDialogProps>()
  const [viewIssueId, setViewIssueId] = useState<string>()
  const navigate = useNavigate()
  const isMobile = useMediaQuery('(max-width:767.98px)')

  useEffect(() => {
    issueService.getAllForMap().subscribe((issues) => {
      issues && setIssues(issues)
    })
  }, [])

  useEffect(() => {
    handleFilterChange([])
  }, [issues])

  const center: Coords = { lat: 37.60512, lng: -0.98623 }
  const zoom: number = 13

  function handleFilterChange(filters: MapFilter[]) {
    let statesids = filters.filter((s) => s.active).map((s) => s.id)

    if (statesids.length > 0) {
      //@ts-ignore
      let filtered = issues.filter((s) => statesids.includes(s.stateID))
      setFilteredIssues(filtered)
    } else {
      setFilteredIssues(issues)
    }
  }

  function createIssue(address: AddressDTO | undefined, lat: number, lng: number) {
    const issue: IssueDTO = {
      description: '',
      stateID: '',
      typeID: '',
      userID: '',
      workflowID: '',
      createdAt: new Date(),
      id: '',
      addressID: '',
      lat: lat,
      lng: lng,
      incNumber: 0,
      phone: '',
      email: '',
      category_id: '',
      responsibleID: '',
    }
    navigate(ROUTE_ISSUES_NEW, { state: { issue: issue, address: address } })
  }

  function viewIssue(id: string) {
    setViewIssueId(id)
  }

  const getIcon = (issue: any) => {
    switch (issue.stateID) {
      case '1b46be45-4930-4757-99da-5f9dfeea2f93':
        return 'state01.svg'
      case '1b46be45-4930-4757-99da-5f9dfeea2f94':
        return 'state02.svg'
      case '1b46be45-4930-4757-99da-5f9dfeea2f95':
        return 'state03.svg'
      case '1b46be45-4930-4757-99da-5f9dfeea2f96':
        return 'state04.svg'
      case '1b46be45-4930-4757-99da-5f9dfeea2f97':
        return 'state05.svg'
      default:
        return ''
    }
  }

  function getMarkers() {
    return filteredIssues.map((issue) => (
      <Marker
        onClick={() => viewIssue(issue.id)}
        key={issue.id}
        id={issue.id}
        lat={issue.lat}
        lng={issue.lng}
        icon={getIcon(issue)}
      />
    ))
  }

  return (
    <div
      style={{
        position: 'absolute',
        zIndex: 0,
        width: isMobile ? '100%' : '85%',
        height: 'calc(100% - 75px)',
      }}
    >
      <div
        style={{
          position: 'absolute',
          zIndex: 0,
          width: '100%',
          height: '100%',
        }}
      >
        {/*@ts-ignore*/}
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY || '' }}
          defaultCenter={center}
          defaultZoom={zoom}
        >
          {getMarkers()}
        </GoogleMapReact>
      </div>
      <div
        style={{
          zIndex: 1,
          position: 'absolute',
          top: 30,
          left: 10,
        }}
      >
        <MapFilters onFilterChange={handleFilterChange} />
        {newIssue && (
          <AddressPickerDialog
            onClose={() => setNewIssue(undefined)}
            open={newIssue.open}
            lat={newIssue.lat}
            title={'Nueva Incidencia'}
            lng={newIssue.lng}
            district={newIssue.district}
            successLabel={'CREAR'}
            onSuccess={(address, lat, lng) => createIssue(address, lat, lng)}
          />
        )}
        {viewIssueId && (
          <ViewIssueDialog
            onClose={() => setViewIssueId(undefined)}
            open={true}
            issueId={viewIssueId}
          />
        )}
      </div>
    </div>
  )
}
