import React, { useEffect, useState } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { makeStyles } from '@material-ui/core'
import { FormControl, NativeSelect, NativeSelectProps } from '@mui/material'
import { useMediaQuery } from '@mui/material'

export interface Option {
  value: string
  label: string
}

const gray = '#E8E8E8'

interface CustomSelectProps extends NativeSelectProps {
  label?: string
  emptyOptionValue?: string
  emptyOption?: boolean
  emptyOptionLabel?: string
  placeholder?: string
  showLabel?: boolean
  options: Option[]
  errorLabel?: string
  onChangeOption?: (value: string) => void
  defaultOneOption?: boolean
}

function CustomSelect({
  defaultOneOption = false,
  emptyOptionValue = '',
  emptyOptionLabel = '',
  emptyOption = false,
  placeholder = '',
  showLabel = true,
  errorLabel,
  options,
  label,
  value,
  children,
  ...rest
}: CustomSelectProps) {
  const minimalSelectClasses = useStyles()
  const isMobile = useMediaQuery('(max-width:767.98px)')

  const iconComponent = (props: any) => {
    return <ExpandMoreIcon className={props.className + ' ' + minimalSelectClasses.icon} />
  }

  const [selectedOption, setSelectedOption] = useState('')

  useEffect(() => {
    rest.onChangeOption && rest.onChangeOption(selectedOption)
  }, [selectedOption])

  useEffect(() => {
    if (value && typeof value === 'string') {
      setSelectedOption(value)
    } else if (!value) {
      setSelectedOption('')
    }
  }, [value])

  useEffect(() => {
    options && options.length === 1 && setSelectedOption(options[0].value)
  }, [options])

  return (
    <FormControl fullWidth={true}>
      {showLabel && (
        <label
          className={minimalSelectClasses.label}
          style={{ fontSize: isMobile ? '14px' : '16px' }}
        >
          {label}
        </label>
      )}
      <NativeSelect
        fullWidth={true}
        variant={'filled'}
        style={{ ...rest.style, marginTop: 0 }}
        onChange={(event) => setSelectedOption(event.target.value)}
        className={minimalSelectClasses.select}
        IconComponent={iconComponent}
        value={selectedOption}
        disableUnderline
        {...rest}
      >
        {placeholder && (
          <option disabled value="">
            {placeholder}
          </option>
        )}
        {emptyOption && <option value={emptyOptionValue}>{emptyOptionLabel}</option>}
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </NativeSelect>
      {errorLabel && <label style={{ color: 'red' }}>{errorLabel}</label>}
    </FormControl>
  )
}

const useStyles = makeStyles({
  label: {
    marginLeft: '4px',
    fontWeight: 'bold',
    color: 'black',
    marginBottom: 5,
  },
  select: {
    '&:MuiInputBase-root': {
      marginTop: 0,
    },
    background: gray,
    height: '3em',
    color: 'black',
    paddingLeft: 15,
    borderStyle: 'none',
    borderRadius: 18,
    boxShadow: 'none',
    '&:focus': {
      borderRadius: 12,
      background: gray,
    },
  },
  icon: {
    color: 'black',
    right: 12,
    position: 'absolute',
    userSelect: 'none',
    pointerEvents: 'none',
  },
  paper: {
    borderRadius: 12,
    marginTop: 8,
  },
})

export default CustomSelect
