export const ROUTE_HOME = `/`
export const ROUTE_LOGIN = '/login'
export const ROUTE_PASSWORD_RECOVER = '/recover'
export const ROUTE_ISSUES = `/issue`
export const ROUTE_MAP = `/map`
export const ROUTE_ADMIN = `/admin`
export const ROUTE_ISSUES_VIEW = `/issue/:id`
export const ROUTE_ISSUES_EDIT = `/issue/edit/:id`
export const ROUTE_ISSUES_NEW = `/issue/new`
export const ROUTE_WORKNOTES = `/worknote`
export const ROUTE_WORKNOTES_VIEW = `/worknote/:id`
export const ROUTE_WORKNOTES_EDIT = `/worknote/edit`
export const ROUTE_WORKNOTES_NEW = `/worknote/edit/:id`
export const ROUTE_USERS = `/users`
export const ROUTE_USERS_EDIT = `/users/edit/:id`
export const ROUTE_USERS_NEW = `/users/new`
export const ROUTE_PASSWORD_CHANGE = '/change-password'
