import React, { useEffect } from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { Box, Grid } from '@mui/material'
import { Category, emptyIssueDTO, IssueDTO } from '../../modules/issues/models/Issue'
import DividerTheme from '../../components/divider/DividerTheme'
import CustomSelect from '../../components/form/CustomSelect'
import CustomTextField from '../../components/form/CustomTextField'
import iconAddLocation from '../../assets/add-location.svg'
import CustomButton from '../../components/form/CustomButton'
import CustomInputFile from '../../components/form/CustomInputFile'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTE_ISSUES } from '../../routes/routes-constants'
import { getIssueContainer } from '../../container/issue-module'
import { IIssueService } from '../../modules/issues'
import {
  ADDRESS_SERVICE_KEY,
  ISSUE_SERVICE_KEY,
  ISSUEHISTORY_SERVICE_KEY,
  ISSUETYPE_SERVICE_KEY,
  STATE_SERVICE_KEY,
} from '../../modules/issues/container'
import {
  Address,
  AddressDTO,
  emptyAddressDTO,
  parseAddress,
} from '../../modules/issues/models/Address'
import { IStateService } from '../../modules/issues/services/StateService'
import { IIssueTypeService } from '../../modules/issues/services/IssueTypeService'
import { State } from '../../modules/issues/models/State'
import { IssueType } from '../../modules/issues/models/IssueType'
import { getUserContainer } from '../../container/user-module'
import { LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { IAddressService } from '../../modules/issues/services/AddressService'
import { v4 as uuidv4 } from 'uuid'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { AddressPickerDialog } from '../map/AddressPickerDialog'
import AddressAutocomplete from '../../components/address-autocomplete/AddressAutocomplete'
import { getDistrict } from '../../common/utils/MapUtils'
import { FileDTO as ThisFile, fromModel as fromModelFile } from '../../modules/files/models/File'
import { FileService } from 'modules/files/services/FileService'
import { FILE_SERVICE_KEY } from 'modules/files/container'
import { Query, QueryParam } from 'common/api/Query'
import { IssueHistoryService } from '../../modules/issues/services/IssueHistoryService'
import { emptyIssueHistoryDTO, IssueHistoryDTO } from '../../modules/issues/models/IssueHistory'
import { useMediaQuery } from '@mui/material'
import { User } from 'modules/users/models/User'
import { UserService } from 'modules/users/services/UserService'

export type IssueEditProps = {
  id?: number
  address?: AddressDTO
  issue?: IssueDTO
} & RouteProps

const issueService = getIssueContainer().get<IIssueService>(ISSUE_SERVICE_KEY)
const issueHistoryService = getIssueContainer().get<IssueHistoryService>(ISSUEHISTORY_SERVICE_KEY)
const fileService = getIssueContainer().get<FileService>(FILE_SERVICE_KEY)
const addressService = getIssueContainer().get<IAddressService>(ADDRESS_SERVICE_KEY)
const stateService = getIssueContainer().get<IStateService>(STATE_SERVICE_KEY)
const issueTypeService = getIssueContainer().get<IIssueTypeService>(ISSUETYPE_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const loggedUser = loggedUserService.get()
const usersService = getUserContainer().get<UserService>(USER_SERVICE_KEY)

export function IssueEdit(props: IssueEditProps) {
  const title = props.title || ''
  const navigate = useNavigate()
  const location = useLocation()
  const [issue, setIssue] = React.useState<IssueDTO>(props.issue || emptyIssueDTO())
  const [categories, setCategories] = React.useState<Category[]>([])
  const [address, setAddress] = React.useState<AddressDTO>(emptyAddressDTO())
  const [states, setStates] = React.useState<State[]>([])
  const [issueTypes, setIssueTypes] = React.useState<IssueType[]>([])
  const [openAddressDialog, setOpenAddressDialog] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [prevState, setPrevState] = React.useState<State>()
  const [errors, setErrors] = React.useState<Map<keyof IssueDTO | keyof AddressDTO, string>>(
    new Map()
  )
  const [images, setImages] = React.useState<ThisFile[]>([])
  const [executionImages, setExecutionImages] = React.useState<ThisFile[]>([])
  const [resolutionImages, setResolutionImages] = React.useState<ThisFile[]>([])
  const [newImages, setNewImages] = React.useState<ThisFile[]>([])
  const [newExecutionImages, setNewExecutionImages] = React.useState<ThisFile[]>([])
  const [newResolutionImages, setNewResolutionImages] = React.useState<ThisFile[]>([])
  const isMobile = useMediaQuery('(max-width:767.98px)')
  const [responsibles, setResponsibles] = React.useState<User[]>([])

  useEffect(() => {
    stateService.getAll().subscribe((states) => {
      issueService.getCategories().subscribe((res) => {
        states && setStates(states)

        if (!location.state?.id) {
          setIssue({
            ...issue,
            category_id: res && res?.length > 0 ? res[0].id : '',
            stateID: states && states?.length > 0 ? states[0].id : '',
          })
        }
        setCategories(res)
      })
    })
    usersService.getAll().subscribe((users) => users && setResponsibles(users))
  }, [])

  useEffect(() => {
    if (location?.state?.id) {
      fileService
        .getFilteredList(
          new Query({
            query: [new QueryParam('issueID', location.state.id || '')],
          })
        )
        .subscribe((res) => {
          let imgs = res.items.filter((i: any) => i.type === 'image').map((a) => fromModelFile(a))

          setImages(imgs)
          setNewImages(imgs)

          let imgsExecution = res.items
            .filter((i: any) => i.type === 'executionImage')
            .map((a) => fromModelFile(a))

          setExecutionImages(imgsExecution)
          setNewExecutionImages(imgsExecution)

          let imgsResolution = res.items
            .filter((i: any) => i.type === 'resolutionImage')
            ?.map((a) => fromModelFile(a))

          setResolutionImages(imgsResolution)
          setNewResolutionImages(imgsResolution)
        })
    }
  }, [])

  React.useEffect(() => {
    if (props.address) {
      setAddress(props.address)
    }
  }, [props.address])

  React.useEffect(() => {
    if (location.state) {
      if (location.state.id) {
        issueService.getByID(location.state.id).subscribe((i) => {
          if (i) {
            setIssue(i.toDTO())
            setAddress(i.address.toDTO())
            setPrevState(new State(i.state.toDTO()))
          }
        })
      }
      location.state.issue && setIssue(location.state.issue)
      location.state.address && setAddress(location.state.address)
    }

    issueTypeService.getAll().subscribe((types) => types && setIssueTypes(types))
  }, [])

  React.useEffect(() => {
    if (errors.size > 0) {
      isFormValid()
    }
  }, [issue])

  React.useEffect(() => {
    if (errors.size > 0) {
      isFormValid()
    }
  }, [address])

  function goBack() {
    navigate(ROUTE_ISSUES)
  }

  const handleAddImage = (type: string, fileb64: string) => {
    let start = fileb64.indexOf('/') + 1
    let end = fileb64.indexOf(';')
    let extension = fileb64.substring(start, end)

    let img = {
      id: uuidv4(),
      issueID: issue.id,
      base64: fileb64.substring(fileb64.indexOf(',') + 1, fileb64.length),
      type: type,
      extension: extension,
    }

    switch (type) {
      case 'image':
        let auximages = newImages ? [...newImages] : []
        auximages.push(img)
        setNewImages(auximages)
        break
      case 'executionImage':
        let auximagesexecution = newExecutionImages ? [...newExecutionImages] : []
        auximagesexecution.push(img)
        setNewExecutionImages(auximagesexecution)
        break
      case 'resolutionImage':
        let auximagesresolution = newResolutionImages ? [...newResolutionImages] : []
        auximagesresolution.push(img)
        setNewResolutionImages(auximagesresolution)
        break
    }
  }

  function handleFormChange(
    field: keyof IssueDTO | keyof AddressDTO,
    value: any,
    isAddress: boolean = false
  ) {
    if (isAddress && address) {
      setAddress({ ...address, [field]: value })
    } else if (issue) {
      setIssue({ ...issue, [field]: value })
    }
  }

  function getFormErrors(): Map<keyof IssueDTO | keyof AddressDTO, string> {
    const err: Map<keyof IssueDTO | keyof AddressDTO, string> = new Map()
    if (!issue?.typeID) err.set('typeID', 'Indique el tipo de incidencia')
    if (!issue?.stateID) err.set('stateID', 'Indique el estado')
    if (!issue?.description) err.set('description', 'Indique una breve descripción')
    if (!issue?.responsibleID) err.set('responsibleID', 'Indique el responsable asignado')

    return err
  }

  function isFormValid(): boolean {
    const err = getFormErrors()
    const valid: boolean = err.size === 0
    setErrors(err)
    return valid
  }

  function saveAddress(cb?: (addr: Address) => void) {
    if (address) {
      if (issue.addressID) {
        addressService
          .update({ ...address, id: issue.addressID })
          .subscribe((a) => a && cb && cb(a))
      } else {
        addressService.add({ ...address, id: uuidv4() }).subscribe((a) => a && cb && cb(a))
      }
    }
  }

  function arraysAreEqual(arr1: any[], arr2: any[]) {
    if (arr1.length !== arr2.length) {
      return false
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i].id !== arr2[i].id) {
        return false
      }
    }

    return true
  }

  function saveIssue(iss: IssueDTO, cb?: (iss: IssueDTO) => void) {
    if (iss.id) {
      issueService.update(iss).subscribe(async (i) => {
        if (!arraysAreEqual(images, newImages)) {
          const deletePromises = images.map(async (image) => {
            await fileService.delete(image.id).toPromise()
          })

          await Promise.all(deletePromises)

          const addPromises = newImages.map(async (image) => {
            image.issueID = iss.id
            await fileService.add(image).toPromise()
          })

          await Promise.all(addPromises)
        }

        if (!arraysAreEqual(executionImages, newExecutionImages)) {
          const deletePromises2 = executionImages.map(async (image) => {
            await fileService.delete(image.id).toPromise()
          })

          await Promise.all(deletePromises2)

          const addPromises2 = newExecutionImages.map(async (image) => {
            image.id = uuidv4()
            image.issueID = iss.id
            await fileService.add(image).toPromise()
          })

          await Promise.all(addPromises2)
        }

        if (!arraysAreEqual(resolutionImages, newResolutionImages)) {
          const deletePromises3 = resolutionImages.map(async (image) => {
            await fileService.delete(image.id).toPromise()
          })

          await Promise.all(deletePromises3)

          const addPromises3 = newResolutionImages.map(async (image) => {
            image.id = uuidv4()
            image.issueID = iss.id
            await fileService.add(image).toPromise()
          })

          await Promise.all(addPromises3)
        }

        if (prevState && prevState.id !== iss.stateID) {
          const history: IssueHistoryDTO = {
            ...emptyIssueHistoryDTO(),
            issueID: iss.id,
            id: uuidv4(),
            userID: loggedUser?.id || '',
            createdAt: new Date(),
            remarks: '',
          }
          stateService.getByID(iss.stateID).subscribe((state) => {
            issueHistoryService.add({ ...history, remarks: state.name }).subscribe(() => {
              setLoading(false)
              navigate(ROUTE_ISSUES)
            })
          })
        } else {
          setLoading(false)
          navigate(ROUTE_ISSUES)
        }
      })
    } else {
      let id = uuidv4()
      console.log('loggedUser: ', loggedUser?.id)
      iss.userID = loggedUser?.id || ''
      issueService.add({ ...iss, userID: loggedUser?.id || '', id: id }).subscribe(async (i) => {
        const addPromises = newImages.map(async (image) => {
          image.issueID = id
          await fileService.add(image).toPromise()
        })

        await Promise.all(addPromises)

        const addPromises3 = newResolutionImages.map(async (image) => {
          image.id = uuidv4()
          image.issueID = id
          await fileService.add(image).toPromise()
        })

        await Promise.all(addPromises3)

        const addPromises2 = newExecutionImages.map(async (image) => {
          image.id = uuidv4()
          image.issueID = id
          await fileService.add(image).toPromise()
        })

        await Promise.all(addPromises2)

        setLoading(false)
        navigate(ROUTE_ISSUES)
      })
    }
  }

  function save() {
    if (address && issue && isFormValid()) {
      setLoading(true)

      saveAddress((storedAddress) => {
        const newIssue: IssueDTO = {
          ...issue,
          addressID: storedAddress?.id || '',
          lng: address.lng || issue.lng,
          lat: address.lat || issue.lat,
        }

        saveIssue(newIssue)
      })
    }
  }

  const handleAddressChange = (place: google.maps.places.PlaceResult) => {
    if (!place || !place.geometry || !place.geometry.location) {
      return
    }
    const lat = place.geometry.location.lat()
    const lng = place.geometry.location.lng()
    const addr = parseAddress(address?.district || '', place.address_components || [], lat, lng)

    addr.address = addr.address.replace(', undefined', '')

    getDistrict(lat, lng)
      .then((district) => {
        setAddress({
          ...addr,
          district: district,
          id: address?.id || '',
          zipcode: addr.zipcode || '-',
        })
      })
      .catch((reason) => {
        console.error(reason)
        setAddress({ ...addr, district: '-', id: address?.id || '', zipcode: addr.zipcode || '-' })
      })
  }

  const handleDeleteImage = (index: number) => {
    setNewImages((prevState) => {
      let auximages = prevState ? [...prevState] : []
      auximages.splice(index, 1)
      return auximages
    })
  }

  const handleDeleteExecutionImage = (index: number) => {
    setNewExecutionImages((prevState) => {
      let auximages = prevState ? [...prevState] : []
      auximages.splice(index, 1)
      return auximages
    })
  }

  const handleDeleteResolutionImage = (index: number) => {
    setNewResolutionImages((prevState) => {
      let auximages = prevState ? [...prevState] : []
      auximages.splice(index, 1)
      return auximages
    })
  }

  return (
    <div>
      <Box style={{ padding: isMobile ? 25 : 24 }}>
        {openAddressDialog && (
          <AddressPickerDialog
            onClose={() => setOpenAddressDialog(false)}
            open={true}
            lat={address?.lat}
            title={'Detalle Dirección'}
            lng={address?.lng}
            district={address?.district}
            successLabel={'OK'}
            onSuccess={(addr, lat, lng) => {
              handleFormChange('lat', lat)
              handleFormChange('lng', lng)
              addr && setAddress(addr)
              setOpenAddressDialog(false)
            }}
          />
        )}
        <DividerTheme title={title.toUpperCase()} />
        <Box style={{ marginTop: 20 }}>
          <Grid container spacing={isMobile ? 2.5 : 4}>
            <Grid item xs={4}>
              <CustomSelect
                label={isMobile ? 'Tipo' : 'Tipo de Incidencia'}
                id={'type'}
                emptyOption
                value={issue?.typeID}
                errorLabel={errors.get('typeID')}
                onChangeOption={(value) => handleFormChange('typeID', value)}
                options={issueTypes.map((it) => {
                  return { value: it.id, label: it.name }
                })}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomSelect
                label={'Responsable'}
                id={'type'}
                emptyOption
                errorLabel={errors.get('responsibleID')}
                onChangeOption={(value) => handleFormChange('responsibleID', value)}
                value={issue?.responsibleID}
                options={responsibles?.map((u) => {
                  return { value: u.id, label: u.firstName + ' ' + u.lastName }
                })}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomSelect
                label={'Estado'}
                id={'type'}
                value={issue?.stateID}
                errorLabel={errors.get('stateID')}
                onChangeOption={(value) => handleFormChange('stateID', value)}
                defaultOneOption
                options={states?.map((it) => {
                  return { value: it.id, label: it.name }
                })}
              />
            </Grid>
            <Grid item xs={isMobile ? 2.75 : 2}>
              <CustomTextField
                fullWidth={true}
                readOnly
                value={address?.district}
                label={'Distrito'}
                errorLabel={errors.get('district')}
              />
            </Grid>
            <Grid item xs={isMobile ? 2.75 : 2}>
              <CustomTextField
                fullWidth={true}
                readOnly
                value={address?.zipcode}
                label={isMobile ? 'CP' : 'Código Postal'}
                errorLabel={errors.get('zipcode')}
              />
            </Grid>
            <Grid item xs={isMobile ? 6.5 : 8}>
              <AddressAutocomplete
                fullWidth={true}
                onPlaceSelect={handleAddressChange}
                value={address?.address}
                label={'Dirección'}
                onChange={(e) => handleFormChange('address', e.target.value, true)}
                errorLabel={errors.get('address')}
                icon={{ icon: iconAddLocation, onClick: () => setOpenAddressDialog(true) }}
              />
            </Grid>

            <Grid item xs={12}>
              <CustomTextField
                fullWidth={true}
                rows={8}
                onChange={(e) => handleFormChange('description', e.target.value)}
                value={issue?.description}
                errorLabel={errors.get('description')}
                multiline
                label={'Descripción de la incidencia (máx. 140 caracteres)'}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                fullWidth={true}
                value={issue?.email}
                errorLabel={errors.get('email')}
                label={'Email'}
                onChange={(e) => setIssue(Object.assign({ ...issue }, { email: e.target.value }))}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                fullWidth={true}
                value={issue?.phone}
                errorLabel={errors.get('phone')}
                label={'Teléfono'}
                onChange={(e) => setIssue(Object.assign({ ...issue }, { phone: e.target.value }))}
              />
            </Grid>
            <Grid item xs={4}>
              {newImages?.map((image, i) => (
                <CustomInputFile
                  base64File={
                    image ? { name: 'IMAGE ' + Number(i + 1), base64: image.base64 } : undefined
                  }
                  fullWidth={true}
                  label={i === 0 ? 'Imagen para el informe' : ''}
                  index={i}
                  handleDeleteImage={handleDeleteImage}
                />
              ))}
              <div style={{ marginTop: '4%' }}>
                <CustomInputFile
                  onFileRead={(file) => file && handleAddImage('image', file.base64)}
                  base64File={undefined}
                  fullWidth={true}
                  index={-1}
                  label={newImages?.length === 0 ? 'Imagen para el informe' : ''}
                />
              </div>
            </Grid>

            <Grid item xs={4}>
              {newExecutionImages?.map((image, i) => (
                <CustomInputFile
                  base64File={
                    image ? { name: 'IMAGE ' + Number(i + 1), base64: image.base64 } : undefined
                  }
                  fullWidth={true}
                  label={i === 0 ? 'Imagen de la ejecución' : ''}
                  index={i}
                  handleDeleteImage={handleDeleteExecutionImage}
                />
              ))}
              <div style={{ marginTop: '4%' }}>
                <CustomInputFile
                  onFileRead={(file) => file && handleAddImage('executionImage', file.base64)}
                  base64File={undefined}
                  fullWidth={true}
                  index={-1}
                  label={newExecutionImages?.length === 0 ? 'Imagen de la ejecución' : ''}
                />
              </div>
            </Grid>

            <Grid item xs={4}>
              {newResolutionImages?.map((image, i) => (
                <CustomInputFile
                  base64File={
                    image ? { name: 'IMAGE ' + Number(i + 1), base64: image.base64 } : undefined
                  }
                  fullWidth={true}
                  label={i === 0 ? 'Imagen de la resolución' : ''}
                  index={i}
                  handleDeleteImage={handleDeleteResolutionImage}
                />
              ))}
              <div style={{ marginTop: '4%' }}>
                <CustomInputFile
                  onFileRead={(file) => file && handleAddImage('resolutionImage', file.base64)}
                  base64File={undefined}
                  fullWidth={true}
                  index={-1}
                  label={newResolutionImages?.length === 0 ? 'Imagen de la resolución' : ''}
                />
              </div>
            </Grid>
          </Grid>
          <Box style={{ marginTop: 60 }} display={'flex'} justifyContent={'space-between'}>
            <CustomButton loading={loading} onClick={save} color={'primary'}>
              {'GUARDAR'}
            </CustomButton>
            <CustomButton onClick={goBack}>{'CANCELAR'}</CustomButton>
          </Box>
        </Box>
      </Box>
    </div>
  )
}
