import React from 'react'
import GoogleMapReact, { Coords } from 'google-map-react'
import { Issue } from '../../modules/issues/models/Issue'

interface MapProps {
  center: Coords
  zoom: number
  issues?: Issue[]
}

const Marker = ({ id, icon, lat, lng }: { id: string; icon: string; lat: number; lng: number }) => (
  <img style={{ cursor: 'pointer' }} src={`../../assets/${icon}`} />
)

const LAYERS = [
  'https://cococheck.es/kmz/D01.kml',
  'https://cococheck.es/kmz/D02.kml',
  'https://cococheck.es/kmz/D03.kml',
  'https://cococheck.es/kmz/D04.kml',
  'https://cococheck.es/kmz/D05.kml',
  'https://cococheck.es/kmz/D06.kml',
  'https://cococheck.es/kmz/D07.kml',
]

const onMapLoad = (mapInstance: any) => {
  LAYERS.forEach((kmz) => {
    const l = new google.maps.KmlLayer({
      url: kmz,
      clickable: true,
      preserveViewport: true,
      suppressInfoWindows: true,
    })
    l.setMap(mapInstance)
  })
}

function IssuesMap({ center, issues, zoom }: MapProps) {
  function getMarkers() {
    return issues?.map((issue, i) => (
      <Marker
        key={issue.id}
        id={issue.id}
        lat={issue.lat}
        lng={issue.lng}
        icon={issue.state.icon}
      />
    ))
  }

  return (
    <div style={{ border: 2, borderRadius: 20, height: '100%', width: '100%' }}>
      {/*@ts-ignore*/}
      <GoogleMapReact
        center={center}
        onGoogleApiLoaded={(maps) => onMapLoad(maps.map)}
        bootstrapURLKeys={{ key: 'AIzaSyDmrtVO_T8mVTHQ6MTBo7R3ACyeiQeVca4' }}
        defaultCenter={center}
        defaultZoom={zoom}
      >
        {getMarkers()}
      </GoogleMapReact>
    </div>
  )
}

export default IssuesMap
