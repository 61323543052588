import { createTheme } from '@material-ui/core'

export const taskManagerTheme = createTheme({
  palette: {
    primary: {
      main: '#0063a6', // --color-dark-blue
    },
    secondary: {
      main: '#f37e31', // --color-orange
      contrastText: '#FFFFFF',
    },
  },
})
