import { Issue } from '../models/Issue'
import { v4 as uuidv4 } from 'uuid'
import { State, StateDTO } from '../models/State'
import { IssueType, IssueTypeDTO } from '../models/IssueType'
import { emptyUserDTO, User } from '../../users/models/User'
import { toModel as stateToModel } from '../../issues/models/State'
import { toModel as typeToModel } from '../../issues/models/IssueType'
import { AddressDTO, toModel as addressToModel } from '../../issues/models/Address'

import { Worknote } from '../../worknotes/models/Worknote'
import { randomInt } from 'crypto'
import { IssueHistory } from '../models/IssueHistory'
import _ from 'lodash'
import icon1 from '../../../../public/assets/state01.svg'
import icon2 from '../../../../public/assets/state02.svg'
import icon3 from '../../../../public/assets/state03.svg'

export const HISTORY_NAMES: { [index: number]: string } = {
  0: 'Creación de la incidencia',
  1: 'Asignación de Operario',
  2: 'Inicio Resolución',
  3: 'Resuelta',
}
export const USERS = [
  new User({
    birthDate: new Date(),
    dni: '',
    email: 'admin@wecitizen.es',
    gender: 0,
    id: 'ccfd9116-2d5d-4161-b6c8-edad2110b161',
    firstName: 'admin',
    lastName: 'admin',
    password: '',
    phone: '',
    roles: [],
    username: '',
    code: '',
  }),
  new User({
    birthDate: new Date(),
    dni: '',
    email: 'mgarcia@wecitizen.es',
    gender: 0,
    id: 'ccfd9116-2d5d-4161-b6c8-edad2110b162',
    firstName: 'Mario',
    lastName: 'García García',
    password: '',
    phone: '',
    roles: [],
    username: '',
    code: '',
  }),
]
export const STATES = ['EN PROGRESO', 'RESUELTA', 'PENDIENTE']
export const ISSUE_TYPES: IssueType[] = [
  new IssueType({ id: uuidv4(), name: 'Vial' }),
  new IssueType({ id: uuidv4(), name: 'Peatonal' }),
  new IssueType({ id: uuidv4(), name: 'Mobiliario' }),
]

export const WORKNOTES: { [id: string]: Worknote[] } = {}
export const HISTORY: { [id: string]: IssueHistory[] } = {}
export let LOCATIONS = [
  { lat: 37.60512, lng: -0.98623 },
  { lat: 37.60746457424432, lng: -0.9913216204831553 },
  { lat: 37.606213154184594, lng: -0.9908621436245689 },
  { lat: 37.60580118715112, lng: -0.9872486355794592 },
  { lat: 37.604688149777196, lng: -0.9880754500056702 },
  { lat: 37.60713915539489, lng: -0.9770158351089264 },
  { lat: 37.61526969099495, lng: -0.9699830040817949 },
  { lat: 37.61199872207878, lng: -0.9854505065529865 },
  { lat: 37.6122278210921, lng: -1.0109649115215558 },
  { lat: 37.60118629154686, lng: -0.9870437383905959 },
  { lat: 37.59732720771666, lng: -0.9849225137674975 },
  { lat: 37.614864503977536, lng: -0.9879581461498935 },
  { lat: 37.60626637978634, lng: -0.9760469528363725 },
  { lat: 37.617117349212286, lng: -0.9984257076570544 },
  { lat: 37.6202882172843, lng: -0.9989566524022612 },
  { lat: 37.61860750758767, lng: -0.9937475027073144 },
  { lat: 37.61967726317411, lng: -0.9903231325032084 },
  { lat: 37.61345011622181, lng: -0.9887798365351732 },
  { lat: 37.59522551247434, lng: -0.9704579838711688 },
  { lat: 37.59977004371172, lng: -0.9622593313135821 },
  { lat: 37.600040507295596, lng: -0.9805828963151639 },
]
const ADDRESS = "C/ Muñoz Pedreño, 5, 30009 Murcia, España'"
const DESCRIPTION =
  'Lorem ipsum dolor sit amet, onsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'

function createState(): StateDTO {
  return {
    id: uuidv4(),
    name: STATES[Math.floor(Math.random() * STATES.length)],
    color: '',
    icon: '',
  }
}

function createAddress(): AddressDTO {
  return {
    id: uuidv4(),
    address: 'C/ Muñoz Pedreño, 5',
    city: 'Murcia',
    state: 'Murcia',
    zipcode: '30009',
    district: 'DISTRITO 1',
    lat: 0,
    lng: 0,
  }
}

function createIssueType(): IssueTypeDTO {
  return ISSUE_TYPES[Math.floor(Math.random() * ISSUE_TYPES.length)]
}

function createUser() {
  return new User({ ...emptyUserDTO(), firstName: 'User' })
}

export function findIssue(id: string): Issue {
  return ISSUES?.filter((i) => i.id === id)[0]
}

export function findWorknote(id: string): Worknote {
  return Object.values(WORKNOTES)
    .flatMap((wn) => wn)
    .filter((i) => i.id === id)[0]
}

function getLatLng(): { lat: number; lng: number } {
  return LOCATIONS.splice(Math.floor(Math.random() * LOCATIONS.length), 1)[0]
}

function createIssue(): Issue {
  const issueId = uuidv4()

  let location = getLatLng()
  let issue = new Issue({
    address: addressToModel(createAddress()),
    description: DESCRIPTION,
    id: issueId,
    lat: location.lat,
    lng: location.lng,
    createdAt: new Date(),
    stateID: '',
    typeID: '',
    workflowID: '',
    userID: '',
    addressID: '',
    state: stateToModel(createState()),
    type: typeToModel(createIssueType()),
    user: createUser(),
    responsible: createUser(),
    incNumber: 0,
    email: '',
    phone: '',
    category_id: '',
    responsibleID: '',
  })

  //  HISTORY[issueId] = _.range(1, 5, 1).map((n) => createHistory(n, issue))
  WORKNOTES[issueId] = _.range(1, 5, 1).map((n) => createWorknote())

  return issue
}

/*

function createHistory(index: number, issue: Issue): IssueHistory {
  let number = index % Object.keys(HISTORY_NAMES).length
  let historyDto = {
    createdAt: new Date(),
    id: uuidv4(),
    issue: issue,
    remarks: HISTORY_NAMES[number],
    user: createUser(),
  }
  return new IssueHistory(historyDto)
}
*/
function createWorknote(): Worknote {
  return new Worknote({
    address: ADDRESS,
    date: new Date(),
    description: DESCRIPTION,
    id: uuidv4(),
    lat: 0.0,
    lng: 0.0,
    remarks: DESCRIPTION,
    userID: 'ccfd9116-2d5d-4161-b6c8-edad2110b162',
    finished: false,
    issue_id: '1b46be45-4930-4757-99da-5f9dfeea2f96',
  })
}

export const ISSUES: Issue[] = [...Array(20)].map((n) => createIssue())
