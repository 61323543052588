import { Container } from '../../common/container/Container'

export type WorknoteContainerConfig = {
  moduleFullUrl: string
}

export type WorknoteProps = {
  parentContainer: Container
  config: WorknoteContainerConfig
}

export const WORKNOTE_MODULE = Symbol('WORKNOTE_MODULE')

export const WORKNOTE_API_KEY = Symbol('WORKNOTE_API_KEY')

export const WORKNOTE_SERVICE_KEY = Symbol('WORKNOTE_SERVICE_KEY')
