import { Container, IInit } from '../../../common/container/Container'
import { Observable } from 'rxjs'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { map } from 'rxjs/operators'
import { Address, AddressDTO, toModel } from '../models/Address'
import { IAddressApi } from '../api/AddressApi'

type Props = {
  apiKey: symbol
}

export interface IAddressService extends IInit {
  getByID(id: string): Observable<Address>

  getAll(): Observable<Address[] | undefined>

  add(e: AddressDTO): Observable<Address | undefined>

  update(e: AddressDTO): Observable<Address | undefined>
}

export class AddressService implements IAddressService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IAddressApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IAddressApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: AddressDTO): Observable<Address | undefined> {
    return this._api.add(e)
  }

  getByID(id: string): Observable<Address> {
    return this._api.getByID(id)
  }

  update(e: AddressDTO): Observable<Address | undefined> {
    return this._api.update(e)
  }

  getAll(): Observable<Address[] | undefined> {
    let all = this._api.getAll()
    return all.pipe(map(res => res?.items.map(i => toModel(i))))
  }
}
