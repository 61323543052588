import { Container, IInit } from '../../../common/container/Container'
import { Observable, of } from 'rxjs'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { IWorknoteApi } from '../api/WorknoteApi'
import { Worknote, WorknoteDTO, WorknoteQuery } from '../models/Worknote'
import { findWorknote } from '../../issues/api/sampleData'
import { Query } from '../../../common/api/Query'

type Props = {
  apiKey: symbol
}

export interface IWorknoteService extends IInit {
  getByID(id: string): Observable<Worknote | undefined>

  getAll(id: string): Observable<Worknote[]>

  getPaginatedByID(id: string, q: Query<WorknoteQuery>): Observable<Worknote[]>

  getCountByID(id: string): Observable<number>

  add(e: Worknote): Observable<Worknote | undefined>

  update(e: WorknoteDTO): Observable<Worknote | undefined>
}

export class WorknoteService implements IWorknoteService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IWorknoteApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IWorknoteApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: Worknote): Observable<Worknote | undefined> {
    return this._api.add(e)
  }

  getByID(id: string): Observable<Worknote | undefined> {
    return this._api.getByID(id)
  }

  update(e: WorknoteDTO): Observable<Worknote | undefined> {
    return this._api.update(e)
  }

  getAll(id: string): Observable<Worknote[]> {
    return this._api.getAll(id)
  }

  getPaginatedByID(id: string, q: Query<WorknoteQuery>): Observable<Worknote[]> {
    return this._api.getPaginatedByID(id, q)
  }

  getCountByID(id: string): Observable<number> {
    return this._api.getCountByID(id)
  }
}
