import { User, UserDTO, UserQuery } from '../models/User'
import { Container, IInit } from '../../../common/container/Container'
import { Observable } from 'rxjs'
import { Query, QueryParam } from '../../../common/api/Query'
import { IStatusService } from '../../../common/status/StatusService'
import { IUserApi } from '../api/UserApi'
import { ItemList } from '../../../common/models/ItemList'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Role } from '../models/Role'
import { UpdatePassword } from '../models/UpdatePassword'
import { UpdatePasswordDTO } from '../models/UpdatePasswordDTO'
import { RegisterUserDTO } from '../models/RegisterUserDTO'

import { UserPending } from '../models/UserPending'
import { map } from 'rxjs/operators'

type Props = {
  apiKey: symbol
}

export interface IUserService extends IInit {
  getByID(id: string): Observable<User | undefined>

  getByUsername(username: string): Observable<User | undefined>

  getFilteredItems(q: Query<UserQuery>): Observable<User[]>

  getUserCount(q: Query<UserQuery>): Observable<number>

  getFilteredList(q: Query<UserQuery>): Observable<ItemList<User>>

  add(e: User): Observable<User | undefined>

  getAll(): Observable<User[] | undefined>

  update(e: UserDTO): Observable<User | undefined>
}

export class UserService implements IUserService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IUserApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IUserApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: UserDTO): Observable<User | undefined> {
    return this._api.add(e)
  }

  getByID(id: string): Observable<User | undefined> {
    return this._api.getByID(id)
  }

  getByUsername(username: string): Observable<User | undefined> {
    return this._api.getByUsername(username)
  }

  getFilteredItems(q: Query<UserQuery>): Observable<User[]> {
    return this._api.getFilteredItems(q).pipe()
  }

  getUserCount(q: Query<UserQuery>): Observable<number> {
    return this._api.getUserCount(q).pipe()
  }

  getFilteredList(q: Query<UserQuery>): Observable<ItemList<User>> {
    return this._api.getFilteredList(q).pipe()
  }

  getAll(): Observable<User[] | undefined> {
    return this._api.getFilteredItems(new Query({ query: [new QueryParam('NAME', '%%')] }))
  }

  update(e: UserDTO): Observable<User | undefined> {
    return this._api.update(e)
  }
}
