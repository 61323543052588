import * as xmljs from 'xml-js'
const KML_PATH = 'https://mantenct.es/kml'

const CACHED_LAYERS: Map<string, any> = new Map()

export interface Coordinate {
  lat: number
  lng: number
}

interface KMLCoordinates {
  _text: string
}

interface KMLLinearRing {
  coordinates: KMLCoordinates
}

interface KMLOuterBoundaryIs {
  LinearRing: KMLLinearRing
}

interface KMLPolygon {
  outerBoundaryIs: KMLOuterBoundaryIs
}

interface KMLMultiGeometry {
  Polygon: KMLPolygon
}

interface KMLPlacemark {
  MultiGeometry: KMLMultiGeometry
}

interface KMLFolder {
  Placemark: KMLPlacemark
}

interface KMLDocument {
  Folder: KMLFolder
}

interface KML {
  Document: KMLDocument
}

function parseCoordinates(coordinates: KMLCoordinates): Coordinate[] {
  const points = coordinates._text.trim().split(' ')
  return points.map((point) => {
    const [lng, lat, _] = point.split(',').map(parseFloat)
    return { lat, lng }
  })
}

function isCoordinateInsidePolygon(coordinate: Coordinate, polygon: Coordinate[]): boolean {
  const [coordinateLat, coordinateLng] = [coordinate.lat, coordinate.lng]

  let isInside = false

  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const [vertex1Lat, vertex1Lng] = [polygon[i].lat, polygon[i].lng]
    const [vertex2Lat, vertex2Lng] = [polygon[j].lat, polygon[j].lng]

    const intersect =
      vertex1Lng > coordinateLng !== vertex2Lng > coordinateLng &&
      coordinateLat <
        ((vertex2Lat - vertex1Lat) * (coordinateLng - vertex1Lng)) / (vertex2Lng - vertex1Lng) +
          vertex1Lat

    if (intersect) {
      isInside = !isInside
    }
  }

  return isInside
}

function isCoordinateInsideKML(coordinate: Coordinate, kml: KML): boolean {
  const coordinates =
    kml.Document.Folder.Placemark.MultiGeometry.Polygon.outerBoundaryIs.LinearRing.coordinates
  const polygonCoordinates = parseCoordinates(coordinates)

  return isCoordinateInsidePolygon(coordinate, polygonCoordinates)
}

export function getDistrict(lat: number, lng: number): Promise<string> {
  const LAYERS = new Array(7).fill('').map((_, index) => `D0${index + 1}.kml`)
  const coordinate = { lat: lat, lng: lng }

  return new Promise<string>(async (resolve, reject) => {
    try {
      for (const kmlFile of LAYERS) {
        if (!CACHED_LAYERS.has(kmlFile)) {
          const response = await fetch(KML_PATH + '/' + kmlFile)
          const kmlData = await response.text()
          const json = xmljs.xml2js(kmlData, { compact: true })
          CACHED_LAYERS.set(kmlFile, json)
        }

        const kmlObject = CACHED_LAYERS.get(kmlFile)
        const kmlDocument = kmlObject.kml.Document
        const descriptionKML = kmlDocument.Folder.Placemark.description._text

        if (isCoordinateInsideKML(coordinate, kmlObject.kml)) {
          resolve(descriptionKML)
        }
      }
      reject('District not found')
    } catch (_) {
      reject('Error during district fetch')
    }
  })
}
