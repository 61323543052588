import { v4 as uuidv4 } from 'uuid'
import { emptyStateDTO, State } from './State'
import { emptyIssueTypeDTO, IssueType } from './IssueType'
import { emptyUserDTO, User } from '../../users/models/User'
import { Address, emptyAddressDTO } from './Address'

export interface IssueDTO {
  id: string
  description: string
  stateID: string
  lat: number
  lng: number
  addressID: string
  typeID: string
  createdAt: Date
  userID: string
  workflowID: string
  incNumber: number
  phone: string
  email: string
  category_id: string
  responsibleID: string
}

export interface IssueCategoryName {
  id: string
  description: string
  stateID: string
  lat: number
  lng: number
  addressID: string
  typeID: string
  createdAt: Date
  userID: string
  workflowID: string
  incNumber: string
  phone: string
  email: string
  category_id: string
  category_name: string
  state: State
  address: Address
  user: User
  type: IssueType
  responsibleID: string
}

export interface Category {
  id: string
  name: string
}

export interface IssueDTOWithEntities extends IssueDTO {
  user: User
  address: Address
  responsible: User
  state: State
  type: IssueType
}

export function emptyIssueDTO(): IssueDTO {
  return {
    id: '',
    userID: '',
    createdAt: new Date(),
    typeID: '',
    description: '',
    lat: 0,
    lng: 0,
    addressID: '',
    stateID: '',
    workflowID: '',
    incNumber: 0,
    email: '',
    phone: '',
    category_id: '',
    responsibleID: '',
  }
}

export class Issue {
  private readonly _id: string
  private readonly _type: IssueType
  private readonly _date: Date
  private readonly _user: User
  private readonly _responsible: User
  private readonly _description: string
  private readonly _lat: number
  private readonly _lng: number
  private readonly _address: Address
  private readonly _state: State
  private readonly _incNumber: number
  private readonly _email: string
  private readonly _phone: string
  private readonly _category_id: string
  private readonly _responsibleID: string

  constructor(p: IssueDTOWithEntities) {
    this._id = p.id || uuidv4()
    this._user = p.user || new User(emptyUserDTO())
    this._responsible = p.responsible || new User(emptyUserDTO())
    this._type = p.type || new IssueType(emptyIssueTypeDTO())
    this._date = new Date(p.createdAt)
    this._description = p.description || ''
    this._lat = p.lat
    this._lng = p.lng
    this._address = p.address || new Address(emptyAddressDTO())
    this._state = p.state || new State(emptyStateDTO())
    this._incNumber = p.incNumber
    this._phone = p.phone
    this._email = p.email
    this._category_id = p.category_id
    this._responsibleID = p.responsibleID
  }

  get id(): string {
    return this._id
  }

  get date(): Date {
    return this._date
  }

  get type(): IssueType {
    return this._type
  }

  get description(): string {
    return this._description
  }

  get lat(): number {
    return this._lat
  }

  get lng(): number {
    return this._lng
  }

  get user(): User {
    return this._user
  }

  get responsible(): User {
    return this._responsible
  }

  get address(): Address {
    return this._address
  }

  get state(): State {
    return this._state
  }

  get phone(): string {
    return this._phone
  }

  get email(): string {
    return this._email
  }

  get responsibleID(): string {
    return this._responsibleID
  }

  get incNumber(): number {
    return this._incNumber
  }

  get categoryId(): string {
    return this._category_id
  }

  toDTO(): IssueDTO {
    return {
      id: this.id,
      workflowID: '',
      userID: this.user.id,
      typeID: this.type.id,
      createdAt: this.date,
      description: this.description,
      lat: this.lat,
      lng: this.lng,
      addressID: this.address.id,
      stateID: this.state.id,
      incNumber: this.incNumber,
      phone: this._phone,
      email: this._email,
      category_id: this._category_id,
      responsibleID: this._responsibleID,
    }
  }
}

export function toModel(d: IssueDTOWithEntities): Issue {
  return new Issue(d)
}

export function fromModel(d: Issue): IssueDTO {
  return d.toDTO()
}

export interface IssueQuery {}
