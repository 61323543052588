import React, { useEffect, useState } from 'react'
import Container from '@material-ui/core/Container'
import { getUserContainer } from './container/user-module'
import { ILoggedUserService } from './modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from './modules/users'
import { useTranslation } from 'react-i18next'
import styles from './Login.module.css'
import { LoggedUser } from 'modules/users/models/LoggedUser'
import {
  Alert,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import CustomButton from './components/form/CustomButton'
import logo from 'assets/left_menu/logo-mantenct.svg'
import CustomTextField from './components/form/CustomTextField'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { newImageData } from 'ol/source/Raster'
import { ROUTE_LOGIN } from './routes/routes-constants'

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

export type ChangePasswordProps = {
}

export default function ChangePassword(props: ChangePasswordProps) {
  const { t } = useTranslation()
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [error, setError] = useState<string>()
  const [searchParams, setSearchParams] = useSearchParams();
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)

  const navigate = useNavigate()

  const handlerChange = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    doChange()
  }

  useEffect(() => {
    const email = getEmail();
    setEmail(email)
  }, [])
  function getEmail() : string{
    const e = searchParams.get("email")
    return e || '';
  }
  function isFormValid() : boolean {
    if(password.length < 5){
      setError("La contraseña debe tener al menos 5 caracteres")
      return false
    } else if(password != repeatPassword){
      setError("las contraseñas no coinciden")
      return false
    }
    return true;
  }

  function handleDialogClose(){
    setOpen(false)
  }

  function successDialog(){
    return <Dialog
      open={open}
      onClose={() => handleDialogClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Cambio de Contraseña"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Su contraseña ha sido modificada correctamente.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => navigate(ROUTE_LOGIN)} color={'primary'}>{'LOGIN'}</CustomButton>
      </DialogActions>
    </Dialog>
  }

  const doChange = () => {
    if (isFormValid()){
      setLoading(true)
      setError(undefined)
      loggedUserService.changePassword({ email: email, newPassword: password }).subscribe((res) => {
        setOpen(true)
      })
    }
  }

  return (
    <div className={styles.background}>
      <Container component='div' className={styles.mainContainer}>
        {successDialog()}
        <div className={styles.container}>
          <img src={logo} alt='Logo Manten CT' className={styles.logo} />
          <form className={styles.formContainer} noValidate onSubmit={handlerChange}>
            <label>{email}</label>
            <CustomTextField
              containerStyle={{ marginTop: 10 }}
              fullWidth={true}
              type={'password'}
              id='password'
              onChange={(e) => setPassword(e.target.value)}
              label={'Nueva Contraseña'}
            />
            <CustomTextField
              containerStyle={{ marginTop: 10 }}
              fullWidth={true}
              type={'password'}
              id='repeat-password'
              onChange={(e) => setRepeatPassword(e.target.value)}
              label={'Repita nueva contraseña'}
            />

            <Box display={'flex'} className={styles.button}>
              <CustomButton fullWidth={true} onClick={() => doChange()} color={'primary'}>
                {loading ? <CircularProgress size={'small'} style={{ color: 'white' }} /> : 'CAMBIAR' }
              </CustomButton>

            </Box>
            {error && <Alert style={{ marginTop: 20 }} severity='error'>{error}</Alert>}
          </form>
        </div>
      </Container>
    </div>
  )
}
