import { Container, ContainerItem } from '../common/container/Container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import {
  ADDRESS_API_KEY,
  ADDRESS_SERVICE_KEY,
  ISSUE_API_KEY,
  ISSUE_SERVICE_KEY,
  ISSUEHISTORY_API_KEY,
  ISSUEHISTORY_SERVICE_KEY,
  IssueProps,
  ISSUES_MODULE,
  ISSUETYPE_API_KEY,
  ISSUETYPE_SERVICE_KEY,
  STATE_API_KEY,
  STATE_SERVICE_KEY,
  WORK_API_KEY,
  WORK_SERVICE_KEY,
} from '../modules/issues/container'
import { IssueApi } from '../modules/issues/api/IssueApi'
import { IssueService } from '../modules/issues/services/IssueService'
import { WORKNOTE_API_KEY, WORKNOTE_SERVICE_KEY } from '../modules/worknotes/container'
import { WorknoteApi } from '../modules/worknotes/api/WorknoteApi'
import { WorknoteService } from '../modules/worknotes/services/WorknoteService'
import { StateService } from '../modules/issues/services/StateService'
import { StateApi } from '../modules/issues/api/StateApi'
import { IssueTypeApi } from '../modules/issues/api/IssueTypeApi'
import { IssueTypeService } from '../modules/issues/services/IssueTypeService'
import { AddressService } from '../modules/issues/services/AddressService'
import { AddressApi } from '../modules/issues/api/AddressApi'
import { IssueHistoryApi } from '../modules/issues/api/IssueHistoryApi'
import { IssueHistoryService } from '../modules/issues/services/IssueHistoryService'
import { FILE_API_KEY, FILE_SERVICE_KEY } from '../modules/files/container'
import { FileService } from 'modules/files/services/FileService'
import { FileApi } from 'modules/files/api/FileApi'
import { WorkApi } from 'modules/issues/api/WorkApi'
import { worker } from 'cluster'
import { WorkService } from 'modules/issues/services/WorkService'

let container: Container

function init(p: IssueProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(ISSUE_API_KEY, new ContainerItem({ instance: new IssueApi() }))
  items.set(STATE_API_KEY, new ContainerItem({ instance: new StateApi() }))
  items.set(ADDRESS_API_KEY, new ContainerItem({ instance: new AddressApi() }))
  items.set(ISSUETYPE_API_KEY, new ContainerItem({ instance: new IssueTypeApi() }))
  items.set(ISSUEHISTORY_API_KEY, new ContainerItem({ instance: new IssueHistoryApi() }))
  items.set(WORKNOTE_API_KEY, new ContainerItem({ instance: new WorknoteApi() }))
  items.set(FILE_API_KEY, new ContainerItem({ instance: new FileApi() }))
  items.set(WORK_API_KEY, new ContainerItem({ instance: new WorkApi() }))

  items.set(
    ISSUE_SERVICE_KEY,
    new ContainerItem({ instance: new IssueService({ apiKey: ISSUE_API_KEY }) })
  )

  items.set(
    STATE_SERVICE_KEY,
    new ContainerItem({ instance: new StateService({ apiKey: STATE_API_KEY }) })
  )

  items.set(
    ADDRESS_SERVICE_KEY,
    new ContainerItem({ instance: new AddressService({ apiKey: ADDRESS_API_KEY }) })
  )

  items.set(
    ISSUETYPE_SERVICE_KEY,
    new ContainerItem({ instance: new IssueTypeService({ apiKey: ISSUETYPE_API_KEY }) })
  )

  items.set(
    ISSUEHISTORY_SERVICE_KEY,
    new ContainerItem({ instance: new IssueHistoryService({ apiKey: ISSUEHISTORY_API_KEY }) })
  )

  items.set(
    WORKNOTE_SERVICE_KEY,
    new ContainerItem({ instance: new WorknoteService({ apiKey: WORKNOTE_API_KEY }) })
  )

  items.set(
    FILE_SERVICE_KEY,
    new ContainerItem({ instance: new FileService({ apiKey: FILE_API_KEY }) })
  )

  items.set(
    WORK_SERVICE_KEY,
    new ContainerItem({ instance: new WorkService({ apiKey: WORK_API_KEY }) })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getIssueContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[ISSUES_MODULE],
      },
    })
  }

  return container
}
