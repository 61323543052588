import React, { useEffect, useState } from 'react'
import { getUserContainer } from './container/user-module'
import { ILoggedUserService } from './modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from './modules/users'
import { useTranslation } from 'react-i18next'
import { LoggedUser } from './modules/users/models/LoggedUser'
import { Box, CssBaseline } from '@mui/material'
import { Main } from './pages/layout'
import Login from './Login'
import CustomAppBar from './components/header/CustomAppBar'
import CustomDrawer from './components/header-component/CustomDrawer'
import {
  ROUTE_HOME,
  ROUTE_ISSUES,
  ROUTE_LOGIN,
  ROUTE_MAP,
  ROUTE_PASSWORD_RECOVER,
  ROUTE_USERS,
  ROUTE_PASSWORD_CHANGE,
} from './routes/routes-constants'
import { Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import RecoveryPassword from './RecoveryPassword'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { esES, enUS, itIT } from '@mui/material/locale'
import { Language } from 'i18n'
import ChangePassword from 'ChangePassword'

const TRANSLATIONS = {
  [Language.SPANISH]: esES,
  [Language.ENGLISH]: enUS,
  [Language.ITALIAN]: itIT,
}

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

function App() {
  const loggedUser = loggedUserService.get()
  const { t } = useTranslation()
  const [user, setUser] = useState<LoggedUser | undefined>(loggedUser)
  const [menuSelected, setMenuSelected] = useState<string>(ROUTE_HOME)
  const [open, setOpen] = useState<boolean>(true)
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()

  const toggleDrawer = () => {
    setOpen(!open)
  }

  React.useEffect(() => {
    setMenuSelected(location.pathname)
    if (location.pathname === '/change-password') {
      navigate(ROUTE_PASSWORD_CHANGE)
    }
  }, [location])

  useEffect(() => {
    if (user) {
      if (loggedUser && loggedUser.roles.includes('worker')) {
        navigate(ROUTE_ISSUES)
        return
      }
    } else {
      if (searchParams.get('email') && location.pathname === '/change-password') {
        navigate(`${ROUTE_PASSWORD_CHANGE}?${searchParams}`)
      } else {
        navigate(ROUTE_LOGIN)
      }
    }
  }, [])

  const handleChangeView = (v: string) => {
    switch (v) {
      case 'DASHBOARD':
        setMenuSelected(ROUTE_HOME)
        navigate(ROUTE_HOME)
        break
      case 'ISSUES':
        setMenuSelected(ROUTE_ISSUES)
        navigate(ROUTE_ISSUES)
        break
      case 'MAP':
        setMenuSelected(ROUTE_MAP)
        navigate(ROUTE_MAP)
        break
      case 'USERS':
        setMenuSelected(ROUTE_USERS)
        navigate(ROUTE_USERS)
        break
      default:
        break
    }
  }

  const adminLayout = (language: Language): JSX.Element => {
    const theme = createTheme(TRANSLATIONS[language])

    return (
      <ThemeProvider theme={theme}>
        <Box className="mainBox">
          <CustomAppBar
            open={open}
            toggleDrawer={toggleDrawer}
            handleChangeView={handleChangeView}
          />
          <CustomDrawer
            open={open}
            toggleDrawer={toggleDrawer}
            handleChangeView={handleChangeView}
            setOpen={setOpen}
          />
          <Main selectedMenu={menuSelected} />
        </Box>
      </ThemeProvider>
    )
  }

  return (
    <>
      <CssBaseline />
      {user ? (
        adminLayout(user.language)
      ) : (
        <>
          <Routes>
            <Route path={ROUTE_LOGIN} element={<Login onLogin={(u) => setUser(u)} />} />
            <Route path={ROUTE_PASSWORD_CHANGE} element={<ChangePassword />} />
            <Route path={ROUTE_PASSWORD_RECOVER} element={<RecoveryPassword />} />
          </Routes>
        </>
      )}
    </>
  )
}

export default App
