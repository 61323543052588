import { Box, FormControl, Input, InputProps } from '@mui/material'
import React from 'react'
import { useMediaQuery } from '@mui/material'

export interface CustomTextFieldRIcon {
  icon?: string
  onClick?: () => void
}

export interface CustomTextFieldProps extends InputProps {
  icon?: CustomTextFieldRIcon
  label?: string
  errorLabel?: string
  containerStyle?: any
}

function CustomTextField({
  icon,
  errorLabel,
  value,
  label,
  containerStyle,
  ...rest
}: CustomTextFieldProps) {
  const gray = '#E8E8E8'
  const isMobile = useMediaQuery('(max-width:767.98px)')

  return (
    <FormControl style={{ ...containerStyle }} fullWidth={true}>
      <label
        style={{
          marginBottom: 5,
          fontWeight: 'bold',
          color: 'black',
          fontSize: isMobile ? '14px' : '16px',
        }}
      >
        {label}
      </label>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Input
          {...rest}
          fullWidth={true}
          value={value}
          disableUnderline={true}
          style={{
            ...rest.style,
            backgroundColor: gray,
            borderRadius: 20,
            borderWidth: 0,
            padding: 8,
          }}
        />
        {icon && (
          <img
            src={icon.icon}
            onClick={icon.onClick}
            style={{ marginLeft: 20, cursor: 'pointer' }}
          />
        )}
      </Box>
      {errorLabel && (
        <Box>
          <label style={{ color: 'red' }}>{errorLabel}</label>
        </Box>
      )}
    </FormControl>
  )
}

export default CustomTextField
