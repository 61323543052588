import { Box, Card, CardContent, Typography } from '@mui/material'
import { useMediaQuery } from '@mui/material'

interface WidgetProps {
  title?: string
  body?: string
  resume?: string
  color?: string
}

function Widget({ title, body, resume, color }: WidgetProps) {
  const isMobile = useMediaQuery('(max-width:767.98px)')

  return (
    <Card sx={{ minWidth: isMobile ? 180 : 250 }}>
      <CardContent>
        <Typography sx={{ fontWeight: 'bold', fontSize: 17 }} color="text.secondary" gutterBottom>
          {title}
        </Typography>

        <Box
          component="div"
          style={{ width: '100%' }}
          alignItems={'baseline'}
          sx={{ display: 'inline-flex' }}
          justifyContent={'space-between'}
        >
          <Box display={'block'}>
            <Typography variant="h1" style={{ fontSize: isMobile ? 34 : 96 }}>
              {body}
            </Typography>
          </Box>
          <Box display={'inline-block'}>
            <Typography variant="h2" style={{ fontSize: isMobile ? 35 : 60 }} color={color}>
              {resume}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

export default Widget
