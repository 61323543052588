import { v4 as uuidv4 } from 'uuid'

export function parseAddress(district: string, components: any[], lat: number, lng: number) : AddressDTO {
    const streetNumber = components.find(c => c.types && c.types.includes('street_number'))?.long_name
    const streetRoute = components.find(c => c.types && c.types.includes('route'))?.long_name
    const city = components.find(c => c.types && c.types.includes('locality'))?.long_name
    const state = components.find(c => c.types && c.types.includes('administrative_area_level_1'))?.long_name
    const postalCode = components.find(c => c.types && c.types.includes('postal_code'))?.long_name

    return {
      id: '',
      address: streetRoute + ', ' + streetNumber,
      city: city,
      district: district,
      state: state,
      zipcode: postalCode,
      lat: lat,
      lng: lng
    }
}

export interface AddressDTO {
  id: string
  address: string
  city: string
  state: string
  zipcode: string
  district: string
  lat: number
  lng: number
}

export function emptyAddressDTO() {
  return {
    id: uuidv4(),
    address: '',
    city: '',
    state: '',
    zipcode: '',
    district: '',
    lat: 0,
    lng: 0
  }
}

export class Address {
  private readonly _id: string
  private readonly _address: string
  private readonly _city: string
  private readonly _state: string
  private readonly _zipcode: string
  private readonly _district: string
  private readonly _lat: number
  private readonly _lng: number


  constructor(p: AddressDTO) {
    this._id = p.id || ''
    this._address = p.address
    this._city = p.city
    this._state = p.state
    this._zipcode = p.zipcode
    this._district = p.district
    this._lat = p.lat
    this._lng = p.lng
  }

  get id(): string {
    return this._id
  }

  get address(): string {
    return this._address
  }

  get city(): string {
    return this._city
  }

  get state(): string {
    return this._state
  }

  get zipcode(): string {
    return this._zipcode
  }

  get district(): string {
    return this._district
  }

  get lat(): number {
    return this._lat

  } get lng(): number {
    return this._lng
  }

  toDTO(): AddressDTO {
    return {
      id: this.id,
      address: this.address,
      city: this.city,
      state: this.state,
      zipcode: this.zipcode,
      district: this.district,
      lat: this.lat,
      lng: this.lng
    }
  }
}

export function toModel(d: AddressDTO): Address {
  return new Address(d)
}

export function fromModel(d: Address): AddressDTO {
  return {
    id: d.id,
    address: d.address,
    city: d.city,
    state: d.state,
    zipcode: d.zipcode,
    district: d.district,
    lat: d.lat,
    lng: d.lng
  }
}
