import { v4 as uuidv4 } from 'uuid'

export interface StateDTO {
  id: string
  name: string
  icon: string
  color: string
}

export function emptyStateDTO() {
  return {
    id: uuidv4(),
    name: '',
    icon: '',
    color: ''
  }
}

export class State {
  private readonly _id: string
  private readonly _name: string
  private readonly _color: string
  private readonly _icon: string
  constructor(p: StateDTO) {
    this._id = p.id || uuidv4()
    this._name = p.name
    this._color = p.color
    this._icon = p.icon
  }

  get id(): string {
    return this._id
  }

  get name(): string {
    return this._name
  }

  get color(): string {
    return this._color
  }


  get icon(): string {
    return this._icon
  }

  toDTO(): StateDTO {
    return {
      id: this.id,
      name: this.name,
      icon: this.icon,
      color: this.color
    }
  }
}

export function toModel(d: StateDTO): State {
  return new State(d)
}

export function fromModel(d: State): StateDTO {
  return {
    id: d.id,
    name: d.name,
    icon: d.icon,
    color: d.color
  }
}
