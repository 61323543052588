import DividerTheme from '../../components/divider/DividerTheme'
import DataTable from '../../components/table/DataTable'
import { Avatar, Grid, Stack } from '@mui/material'
import React, { useState, useEffect, SetStateAction } from 'react'
import { IssueHistory, IssueHistoryDTO } from '../../modules/issues/models/IssueHistory'
import dayjs from 'dayjs'
import iconAvatar from '../../assets/images/avatar-2.jpg'
import { getIssueContainer } from '../../container/issue-module'
import { IIssueHistoryService } from '../../modules/issues/services/IssueHistoryService'
import { ISSUE_SERVICE_KEY, ISSUEHISTORY_SERVICE_KEY } from '../../modules/issues/container'
import { Query } from '../../common/api/Query'
import { getUserContainer } from '../../container/user-module'
import { IUserService, USER_SERVICE_KEY } from '../../modules/users'
import { IssueService } from '../../modules/issues/services/IssueService'

interface HistoryView extends IssueHistoryDTO {
  userView: JSX.Element
  descriptionView: string
  dateView: string
}

interface ActionsTableProps {
  issueID: string
}

const issueService = getIssueContainer().get<IssueService>(ISSUE_SERVICE_KEY)
const issueHistoryService = getIssueContainer().get<IIssueHistoryService>(ISSUEHISTORY_SERVICE_KEY)
const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)

//async function historyToView(h: IssueHistory): HistoryView {
const historyToView = async (h: IssueHistory, issueID: string): Promise<HistoryView> => {
  let view = {
    id: h.id,
    userID: h.userID,
    createdAt: h.createdAt,
    issueID: h.issueID,
    remarks: h.remarks,
    descriptionView: h.remarks,
    dateView: dayjs(h.createdAt).format('DD/MM/YYYY HH:mm'),
  }

  const user = await userService.getByID(h.userID).toPromise()
  if (user) {
    return {
      ...view,
      userView: (
        <Stack direction={'row'} spacing={2} style={{ alignItems: 'center' }}>
          <Avatar alt={user?.firstName} src={iconAvatar} />
          <label>{`${user?.firstName} ${user?.lastName}`}</label>
        </Stack>
      ),
    }
  }
  const issue = await issueService.getByID(issueID).toPromise()
  if (issue) {
    return {
      ...view,
      userView: (
        <Stack direction={'row'} spacing={2} style={{ alignItems: 'center' }}>
          <Avatar alt={issue.email} src={iconAvatar} />
          <label>{issue.email}</label>
        </Stack>
      ),
    }
  }
  return {
    ...view,
    userView: (
      <Stack direction={'row'} spacing={2} style={{ alignItems: 'center' }}>
        <Avatar alt={'no asignado'} src={iconAvatar} style={{ filter: 'grayscale(1)' }} />
        <label>{'No asignado'}</label>
      </Stack>
    ),
  }
}

const historyColumns = [
  { id: 'remarks', name: 'Descripción', size: '50%' },
  { id: 'dateView', name: 'Fecha', size: '20%' },
  { id: 'userView', name: 'Usuario', size: '30%' },
]

const updateHistory = (
  id: string,
  page: number,
  rowsPerPage: number,
  setHistory: React.Dispatch<SetStateAction<HistoryView[]>>
) => {
  issueHistoryService
    .getPaginatedListByIssueID(
      id,
      new Query({
        pager: {
          offset: page * rowsPerPage,
          limit: rowsPerPage,
        },
      })
    )
    .subscribe(async (history) => {
      if (history) {
        setHistory(
          await Promise.all(
            history.map(async (h) => {
              return await historyToView(h, id)
            })
          )
        )
      }
    })
}

const INIT_PAGE = 0
const DEFAULT_ROWS_PER_PAGE = 5

export function ActionsTable({ issueID }: ActionsTableProps) {
  const [history, setHistory] = useState<HistoryView[]>([])
  const [currentPage, setCurrentPage] = useState(INIT_PAGE)
  const [historyCount, setHistoryCount] = useState(-1)

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setCurrentPage(newPage)
  }

  useEffect(() => {
    issueHistoryService.getCountByIssueID(issueID).subscribe((count) => setHistoryCount(count))
    updateHistory(issueID, INIT_PAGE, DEFAULT_ROWS_PER_PAGE, setHistory)
  }, [])

  useEffect(() => {
    updateHistory(issueID, currentPage, DEFAULT_ROWS_PER_PAGE, setHistory)
  }, [currentPage])

  return (
    <Grid item xs={6} mb={5}>
      <DividerTheme fontSize={17} fontColor={'var(--color-red_insitu)'} title={'Acciones'} />
      <DataTable
        withActions={false}
        data={history}
        columns={historyColumns}
        totalRows={historyCount}
        currentPage={currentPage}
        rowsPerPage={DEFAULT_ROWS_PER_PAGE}
        rowsPerPageOptions={[]}
        handleChangePage={handleChangePage}
        entity="actions"
      />
    </Grid>
  )
}
