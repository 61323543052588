import { v4 as uuidv4 } from 'uuid'

export interface WorkDTO {
  id: string
  description: string
  image1ID: string
  image2ID: string
  issueID: string
  state: string
  createdAt: Date
  updatedAt: Date
}

export function emptyWorkDTO() {
  return {
    id: uuidv4(),
    description: '',
    image1ID: '',
    image2ID: '',
    issueID: '',
    state: '',
    createdAt: new Date(),
    updatedAt: new Date(),
  }
}

export class Work {
  private readonly _id: string
  private readonly _description: string
  private readonly _image1ID: string
  private readonly _image2ID: string
  private readonly _issueID: string
  private readonly _state: string
  private readonly _createdAt: Date
  private readonly _updatedAt: Date

  constructor(p: WorkDTO) {
    this._id = p.id || uuidv4()
    this._description = p.description
    this._image1ID = p.image1ID
    this._image2ID = p.image2ID
    this._issueID = p.issueID
    this._state = p.state
    this._createdAt = p.createdAt
    this._updatedAt = p.updatedAt
  }

  get id(): string {
    return this._id
  }

  get description(): string {
    return this._description
  }

  get image1ID(): string {
    return this._image1ID
  }

  get image2ID(): string {
    return this._image2ID
  }

  get issueID(): string {
    return this._issueID
  }

  get state(): string {
    return this._state
  }

  get createdAt(): Date {
    return this._createdAt
  }

  get updatedAt(): Date {
    return this._updatedAt
  }

  toDTO(): WorkDTO {
    return {
      id: this.id,
      description: this.description,
      image1ID: this.image1ID,
      image2ID: this.image2ID,
      issueID: this.issueID,
      state: this.state,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    }
  }
}

export function toModel(d: WorkDTO): Work {
  return new Work(d)
}

export function fromModel(d: Work): WorkDTO {
  return {
    id: d.id,
    description: d.description,
    image1ID: d.image1ID,
    image2ID: d.image2ID,
    issueID: d.issueID,
    state: d.state,
    createdAt: d.createdAt,
    updatedAt: d.updatedAt,
  }
}
