import { Box, Button, ButtonProps, FormControl, Typography } from '@mui/material'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import iconAdd from '../../assets/icon-add.svg'
import removeIcon from '../../assets/remove.svg'
import { useMediaQuery } from '@mui/material'

interface CustomInputFileProps extends ButtonProps {
  label?: string
  errorLabel?: string
  base64File?: Base64File
  onFileRead?: (file?: Base64File) => void
  index: number
  handleDeleteImage?: (index: number) => void
}

interface Base64File {
  name: string
  base64: string
}

const MAX_FILE_SIZE = 10 * 1024 * 1024 // 10MB

function CustomInputFile({
  label,
  base64File,
  onFileRead,
  errorLabel,
  children,
  index,
  handleDeleteImage,
  ...rest
}: CustomInputFileProps) {
  const inputRef = useRef<any>(null)
  const [file, setFile] = useState<Base64File>()
  const isMobile = useMediaQuery('(max-width:767.98px)')

  useEffect(() => {
    onFileRead && onFileRead(file)
  }, [file])

  useEffect(() => {
    !file && setFile(base64File)
  }, [base64File])

  const resetFileInput = () => {
    handleDeleteImage && handleDeleteImage(index)
    setFile(undefined)
    if (inputRef && inputRef.current) {
      inputRef.current.value = null
    }
  }

  async function handleFileRead(e: ChangeEvent<HTMLInputElement>) {
    const base64ToSize = (base64: string | ArrayBuffer | null): number => {
      if (!base64) {
        return -1
      }

      if (typeof base64 === 'string') {
        const fileBase64 = base64.substring(base64.indexOf(',') + 1, base64.length)

        const binaryString = atob(fileBase64)
        const bytes = new Uint8Array(binaryString.length)
        for (let i = 0; i < binaryString.length; i++) {
          bytes[i] = binaryString.charCodeAt(i)
        }
        return bytes.buffer.byteLength
      }
      return base64.byteLength
    }

    const f = e.target?.files?.[0]
    if (f) {
      const base64 = await convertBase64(f)
      const size = base64ToSize(base64)

      if (size < 0 || size > MAX_FILE_SIZE) {
        alert(`Tamaño máximo del archivo ${MAX_FILE_SIZE / (1024 * 1024)}MB`)
        return
      }

      if (typeof base64 === 'string') {
        setFile({ name: f.name, base64: base64 })
      }
    }
  }

  function convertBase64(file: File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  return (
    <FormControl fullWidth={true}>
      <label
        style={{
          marginBottom: 5,
          fontWeight: 'bold',
          color: 'black',
          fontSize: isMobile ? '13.5px' : '16px',
        }}
      >
        {label}
      </label>
      <Box display={'inline-flex'} justifyContent={'flex-start'}>
        <Button
          style={{
            textTransform: 'none',
            color: 'white',
            justifyContent: 'flex-start',
            fontWeight: 'bold',
            backgroundColor: 'black',
            width: 400,
            borderRadius: 20,
            borderWidth: 0,
            padding: 8,
          }}
          variant="contained"
          component="label"
          disabled={index != -1}
        >
          <Typography
            variant={'button'}
            align={'left'}
            style={{ fontSize: isMobile ? 9.5 : 18, paddingRight: 10 }}
          >
            {index === -1 ? 'Examinar...' : file ? file.name : ''}
          </Typography>
          {index === -1 && (
            <>
              <img src={iconAdd} style={{ position: 'absolute', right: isMobile ? 3 : 10 }} />
              <input
                ref={inputRef}
                type="file"
                accept={'image/png, image/jpg, image/jpeg'}
                onChange={(e) => handleFileRead(e)}
                hidden
              />
            </>
          )}
          {children}
        </Button>
        {index > -1 && (
          <img
            onClick={() => resetFileInput()}
            src={removeIcon}
            style={{ cursor: 'pointer', width: isMobile ? '20%' : '15%' }}
          />
        )}
      </Box>
      {errorLabel && (
        <Box>
          <label style={{ color: 'red' }}>{errorLabel}</label>
        </Box>
      )}
    </FormControl>
  )
}

export default CustomInputFile
