import React, { useEffect, useState } from 'react'
import { AppRouter } from '../../routes/AppRouter'
import style from './Main.module.css'
import { ROUTE_HOME, ROUTE_MAP } from '../../routes/routes-constants'

type MainProps = {
  selectedMenu: string
}

export function Main({ selectedMenu }: MainProps) {
  const mainContainer = React.useRef<HTMLDivElement>(null)
  const [className, setClassName] = useState<string>(getClassName())

  useEffect(() => {
    if (mainContainer.current !== null) {
      mainContainer.current.scrollTop = 0
    }
  }, [selectedMenu])

  function getClassName() {
    switch (selectedMenu) {
      case ROUTE_MAP:
      case ROUTE_HOME:
        return style.mainContainer
    }
    return style.mainContainerPadding
  }

  return (
    <div ref={mainContainer} className={className} id="mainContainer">
      <AppRouter />
    </div>
  )
}
