import React, { useState, useEffect } from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { Box, Grid } from '@mui/material'
import BoxTheme from '../../components/box/BoxTheme'
import { useNavigate, useParams } from 'react-router-dom'
import DividerTheme from '../../components/divider/DividerTheme'
import { Worknote } from '../../modules/worknotes/models/Worknote'
import { getIssueContainer } from '../../container/issue-module'
import CustomButton from '../../components/form/CustomButton'
import CustomTextField from '../../components/form/CustomTextField'
import { Work, WorkDTO, fromModel } from 'modules/issues/models/Work'
import { IWorkService } from 'modules/issues/services/WorkService'
import { WORK_SERVICE_KEY } from 'modules/issues/container'
import { FILE_SERVICE_KEY } from 'modules/files'
import { FileService } from 'modules/files/services/FileService'
import CustomInputFile from 'components/form/CustomInputFile'
import { useMediaQuery } from '@mui/material'

export type WorknoteDetailProps = {
  worknote?: Worknote
} & RouteProps

const workService = getIssueContainer().get<IWorkService>(WORK_SERVICE_KEY)
const fileService = getIssueContainer().get<FileService>(FILE_SERVICE_KEY)

export function WorknoteDetail(props: WorknoteDetailProps) {
  const title = props.title || ''
  const { id } = useParams()
  const [work, setWork] = useState<WorkDTO>()
  const navigate = useNavigate()
  const [image1, setImage1] = useState<any>()
  const [image2, setImage2] = useState<any>()
  const [image1Before, setImage1Before] = useState<any>('')
  const [image2Before, setImage2Before] = useState<any>('')
  const isMobile = useMediaQuery('(max-width:767.98px)')

  useEffect(() => {
    if (!work) {
      return
    }

    work.image1ID &&
      fileService.getByID(work.image1ID).subscribe((res) => {
        setImage1(res)
        setImage1Before(res)
      })

    work.image2ID &&
      fileService.getByID(work.image2ID).subscribe((res) => {
        setImage2(res)
        setImage2Before(res)
      })
  }, [work])

  useEffect(() => {
    if (id) {
      workService.getByID(id).subscribe((res) => {
        setWork(fromModel(res))
      })
    }
  }, [])

  const formatDate = (date: Date) => {
    const d = new Date(date)
    const day = String(d.getDate()).padStart(2, '0')
    const month = String(d.getMonth() + 1).padStart(2, '0')
    const year = d.getFullYear()
    return `${day}/${month}/${year}`
  }

  const handleFinish = async () => {
    if (!work) return

    let id1 = ''
    if (image1 && image1Before != image1) {
      await fileService.delete(image1Before.id).toPromise()
      id1 = image1.id
      await fileService.add(image1).toPromise()
    }

    let id2 = ''
    if (image2 && image2Before != image2) {
      await fileService.delete(image2Before.id).toPromise()
      id2 = image2.id
      await fileService.add(image2).toPromise()
    }

    let work2 = Object.assign(
      { ...work },
      { state: 'Obra ejecutada', updatedAt: new Date(), image1ID: id1, image2ID: id2 }
    )
    if (work2) {
      workService.update(work2).subscribe(() => {
        navigate(-1)
      })
    }
  }

  function onChangeField(field: keyof Work, value: any) {
    if (work) {
      setWork({ ...work, [field]: value })
    }
  }

  const renderImageOrDefaultText = (image: string | undefined) => {
    return image ? (
      <Box style={{ width: '100%' }} component="img" src={image}></Box>
    ) : (
      <Box style={{ width: '100%' }}>{'Sin Imagen'}</Box>
    )
  }

  const handleChangeImage = (type: string, fileb64: string) => {
    let start = fileb64.indexOf('/') + 1
    let end = fileb64.indexOf(';')
    let extension = fileb64.substring(start, end)

    let id1 = type == 'image1' && image1 ? image1.id : id + '-obra'
    let id2 = type == 'image2' && image2 ? image2.id : id + '-obra'

    let img = {
      id: type == 'image1' ? id1 : id2,
      issueID: id,
      base64: fileb64.substring(fileb64.indexOf(',') + 1, fileb64.length),
      type: 'work',
      extension: extension,
    }

    type == 'image1' ? setImage1(img) : setImage2(img)
  }

  return (
    <div>
      <Box style={{ padding: isMobile ? 25 : 24 }}>
        <DividerTheme title={title} />
        <Box style={{ marginTop: 20 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <BoxTheme flex={1} flexDirection={'column'} title={'Fecha de Creación'}>
                    {formatDate(work?.createdAt || new Date())}
                  </BoxTheme>
                </Grid>
                <Grid item xs={6}>
                  <BoxTheme flex={1} flexDirection={'column'} title={'Fecha de Actualización'}>
                    {formatDate(work?.updatedAt || new Date())}
                  </BoxTheme>
                </Grid>
                <Grid item xs={12}>
                  <BoxTheme title={'Descripción'}>
                    {work?.state == 'Obra ejecutada' ? (
                      work?.description
                    ) : (
                      <CustomTextField
                        onChange={(event) => onChangeField('description', event.target.value)}
                        fullWidth={true}
                        rows={4}
                        multiline
                        value={work?.description}
                      />
                    )}
                  </BoxTheme>
                </Grid>
                <Grid item xs={6} mb={5}>
                  <BoxTheme title={'Imagen 1'}>
                    {renderImageOrDefaultText(`data:png;base64,${image1?.base64}`)}
                  </BoxTheme>
                </Grid>
                <Grid item xs={6} mb={5}>
                  <BoxTheme title={'Imagen 2'}>
                    {renderImageOrDefaultText(`data:png;base64,${image2?.base64}`)}
                  </BoxTheme>
                </Grid>
                {work?.state != 'Obra ejecutada' && (
                  <>
                    <Grid item xs={6} mb={5}>
                      <CustomInputFile
                        onFileRead={(file) => file && handleChangeImage('image1', file.base64)}
                        base64File={image1 ? { name: 'IMAGE 1', base64: image1.base64 } : undefined}
                        fullWidth={true}
                        index={0}
                        label={'Imagen'}
                      />
                    </Grid>
                    <Grid item xs={6} mb={5}>
                      <CustomInputFile
                        onFileRead={(file) => file && handleChangeImage('image2', file.base64)}
                        base64File={image2 ? { name: 'IMAGE 2', base64: image2.base64 } : undefined}
                        fullWidth={true}
                        index={0}
                        label={'Imagen 2'}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={6}>
                  <BoxTheme flex={1} flexDirection={'column'} title={'Estado'}>
                    {work?.state}
                  </BoxTheme>
                </Grid>
                <Grid item xs={6} display={'flex'} justifyContent={'center'}>
                  <CustomButton
                    color={'primary'}
                    onClick={() => {
                      handleFinish()
                    }}
                    disabled={work?.state == 'Obra ejecutada'}
                  >
                    {'Finalizar'}
                  </CustomButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  )
}
