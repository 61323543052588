import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import CustomTextField from '../../components/form/CustomTextField'
import { AddressDTO, parseAddress } from '../../modules/issues/models/Address'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'

export type AddressPickerProps = {
  lat?: number
  lng?: number
  district?: string
  onAddressChange?: (address: AddressDTO | undefined) => void
  onLngChange: (lat: number) => void
  onLatChange: (lat: number) => void
}

export function AddressPicker(props: AddressPickerProps) {
  const geocoder = new google.maps.Geocoder()
  const [lat, setLat] = useState<number>(props.lat || 0)
  const [lng, setLng] = useState<number>(props.lng || 0)
  const [district, setDistrict] = useState<string>(props.district || '')
  const [address, setAddress] = useState<AddressDTO>()
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
  })

  const mapContainerStyle = {
    width: '100%',
    height: '400px',
  }

  useEffect(() => {
    props.onAddressChange && props.onAddressChange(address)
  }, [address])

  useEffect(() => {
    reverseGeocoding()
    props.onLatChange && props.onLatChange(lat)
    props.onLngChange && props.onLngChange(lng)
  }, [lat, lng])

  function reverseGeocoding() {
    const latlng: google.maps.LatLngLiteral = { lat: lat, lng: lng }

    if (lat !== 0 && lng !== 0) {
      geocoder
        .geocode({ location: latlng })
        .then((response) => {
          if (response.results[0]) {
            const addr = parseAddress(district, response.results[0].address_components, lat, lng)
            setAddress(addr)
          } else {
            console.warn('No results found')
          }
        })
        .catch((e) => console.error('Geocoder failed due to: ' + e))
    }
  }

  const onMarkerDragEnd = (event: any) => {
    const { latLng } = event
    const lat = latLng.lat()
    const lng = latLng.lng()
    setLat(lat)
    setLng(lng)
  }

  const onMapClick = (event: any) => {
    const { latLng } = event
    const lat = latLng.lat()
    const lng = latLng.lng()
    setLat(lat)
    setLng(lng)
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <Grid item xs={12}>
          <CustomTextField value={district} fullWidth={true} label={'Distrito'} />
          <CustomTextField
            containerStyle={{ marginTop: 10 }}
            value={lat}
            fullWidth={true}
            label={'Latitud'}
          />
          <CustomTextField
            containerStyle={{ marginTop: 10 }}
            value={lng}
            fullWidth={true}
            label={'Longitud'}
          />
          <CustomTextField
            containerStyle={{ marginTop: 10 }}
            value={address?.zipcode}
            fullWidth={true}
            label={'Código Postal'}
          />
          <CustomTextField
            containerStyle={{ marginTop: 10 }}
            value={address?.address}
            fullWidth={true}
            label={'Dirección'}
          />
        </Grid>
      </Grid>
      <Grid item xs={6}>
        {lat && lng ? (
          <>
            {isLoaded ? (
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={{
                  lat: lat,
                  lng: lng,
                }}
                options={{ mapTypeId: 'satellite' }}
                zoom={500}
                onClick={onMapClick}
              >
                <Marker
                  position={{
                    lat: lat,
                    lng: lng,
                  }}
                  draggable={true}
                  onDragEnd={onMarkerDragEnd}
                />
              </GoogleMap>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  )
}
