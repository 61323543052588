import { Container, IInit } from '../../../common/container/Container'
import { Observable, of } from 'rxjs'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { catchError, map } from 'rxjs/operators'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { toModel, Worknote, WorknoteDTO, WorknoteQuery } from '../models/Worknote'
import { WorknoteContainerConfig } from '../container'
import { ItemList } from '../../../common/models/ItemList'
import { Query } from '../../../common/api/Query'
import { prepareURL } from '../../../common/api/http-helpers'

export interface IWorknoteApi extends IInit {
  getAll(id: string): Observable<Worknote[]>

  getByID(id: string): Observable<Worknote | undefined>

  getPaginatedByID(id: string, q: Query<WorknoteQuery>): Observable<Worknote[]>

  getCountByID(id: string): Observable<number>

  add(e: Worknote): Observable<Worknote | undefined>

  update(e: WorknoteDTO): Observable<Worknote | undefined>
}

export class WorknoteApi implements IWorknoteApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as WorknoteContainerConfig).moduleFullUrl
  }

  add(e: Worknote): Observable<Worknote | undefined> {
    return this._httpClient
      .post<Worknote>({
        url: `${this._url}/worknotes`,
        body: e.toDTO(),
      })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getByID(id: string): Observable<Worknote | undefined> {
    return this._httpClient.get<Worknote>({ url: `${this._url}/worknotes/${id}` }).pipe(
      map<WorknoteDTO, Worknote>((dto) => toModel(dto)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  update(e: WorknoteDTO): Observable<Worknote | undefined> {
    return this._httpClient.put<Worknote>({ url: `${this._url}/worknotes`, body: e }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getAll(id: string): Observable<Worknote[]> {
    return this._httpClient
      .get<ItemList<Worknote>>({ url: `${this._url}/worknotes-by-issue/${id}` })
      .pipe(
        map<ItemList<Worknote>, Worknote[]>((dto) => dto.items),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of([])
        })
      )
  }

  getPaginatedByID(id: string, q: Query<WorknoteQuery>): Observable<Worknote[]> {
    return this._httpClient
      .get<ItemList<Worknote>>({
        url: prepareURL(`${this._url}/paginated-worknotes-by-issue/${id}`, q),
      })
      .pipe(
        map<ItemList<Worknote>, Worknote[]>((dto) => dto.items),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of([])
        })
      )
  }

  getCountByID(id: string): Observable<number> {
    return this._httpClient
      .get<number>({
        url: `${this._url}/worknotes-by-issue-count/${id}`,
      })
      .pipe(
        map((res) => res),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(-1)
        })
      )
  }
}
