import React, { useEffect } from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { Grid } from '@mui/material'
import { State } from '../../modules/issues/models/State'
import { getIssueContainer } from '../../container/issue-module'
import { ISSUE_SERVICE_KEY } from '../../modules/issues/container'
import Widget from '../../components/dashboard/Widget'
import { IIssueService } from '../../modules/issues'
import { useMediaQuery } from '@mui/material'

const issueService = getIssueContainer().get<IIssueService>(ISSUE_SERVICE_KEY)

export function Home(props: RouteProps) {
  const [states, setStates] = React.useState<State[]>([])
  const isMobile = useMediaQuery('(max-width:767.98px)')

  useEffect(() => {
    issueService.getDashBoardData().subscribe((res) => {
      res && setStates(res)
    })
  }, [])

  function widget(st: any, index: number) {
    return (
      <Widget key={index} color={st.Color} title={st.Type} resume={st.Percentage} body={st.Total} />
    )
  }

  return (
    <Grid style={{ padding: 20 }} container rowSpacing={3} columnSpacing={3}>
      {states.map((st, i) => (
        <Grid key={i} item xs={isMobile ? 6 : 4}>
          {widget(st, i)}
        </Grid>
      ))}
    </Grid>
  )
}
